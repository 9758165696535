import dashboardRoute from './Dashboard/Route';
import deviceRoute from './Device/Route';
import takRoute from './Tak/Route';
import trackingRoute from './Track/Route';
import trackingDetailsRoute from './TrackingDetails/Route';
import userAdminRoute from './UserAdmin/Route';

const viewRoutes = [
  ...dashboardRoute,
  ...deviceRoute,
  ...takRoute,
  ...trackingRoute,
  ...trackingDetailsRoute,
  ...userAdminRoute,
];

export default viewRoutes;
