import type { DeviceFirmware } from '@stonewall-defense/atlas-common';

export default function formatFirmware(
  {
    appVersionMajor,
    appVersionMinor,
    appVersionPatch,
  }: DeviceFirmware,
) {
  const noFw = (appVersionMajor ?? appVersionMinor ?? appVersionPatch) === null;
  if (noFw) {
    return 'N/A';
  }

  return `v${appVersionMajor || '0'}.${appVersionMinor || '0'}.${appVersionPatch || '0'}`;
}
