// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import type { Nullable } from 'types/common';
import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { usePatchRecordDescription } from 'apiHooks/Track.Hook';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface IEditRecordDescriptionModal {
  recordId: number,
  recordDescription: Nullable<string>,
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function EditRecordDescriptionModal({ recordId, recordDescription }: IEditRecordDescriptionModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpVal, setTmpVal] = React.useState(recordDescription);

  const patch = usePatchRecordDescription(recordId);

  const toggle = () => setModalOpen(!modalOpen);
  const resetState = () => setTmpVal(recordDescription);

  const onSubmit = () => patch(tmpVal)
    .then(() => {
      toggle();
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{recordDescription}</span>
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <AtlasModal
        title="Update Record Description"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={recordDescription !== tmpVal}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="inputRecordDescription"
            autoComplete="off"
            defaultValue={tmpVal || ''}
            onChange={(event) => setTmpVal(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="inputRecordDescription">
            Record Description
          </label>
        </div>
      </AtlasModal>
    </>
  );
}

export default EditRecordDescriptionModal;
