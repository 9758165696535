import type { FormattedLocation } from '@stonewall-defense/atlas-common';

export default function mapLocations(locations: FormattedLocation[]) {
  return locations.reduce<Record<string, FormattedLocation>>(
    (agg, curr) => ({
      ...agg,
      [curr.serial]: curr,
    }),
    {},
  );
}
