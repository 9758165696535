import type { ICustomFilter } from 'types/filter';
import { FILTER_TYPES } from 'utils/Constants';

const AG_GRID_DEVICE_FIELDS = [
  'serial',
  'reportTime',
  'location',
  'posMethod',
  'battery',
  'firmware',
  'moving',
  'paramsSynced',
] as const;

type DeviceField = typeof AG_GRID_DEVICE_FIELDS[number];

const AG_GRID_DEVICE_HEADER_MAP: Record<DeviceField, string> = {
  serial: 'Serial Number',
  reportTime: 'Last Check-in',
  location: 'Location',
  posMethod: 'Accuracy',
  battery: 'Battery',
  firmware: 'Firmware',
  moving: 'Moving',
  paramsSynced: 'Synced',
};

const DEVICE_FIELD_INITIAL_FILTERS: ICustomFilter<DeviceField> = {
  serial: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  reportTime: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: false,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  posMethod: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  battery: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  firmware: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  moving: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
  paramsSynced: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
};

export {
  AG_GRID_DEVICE_FIELDS,
  type DeviceField,
  AG_GRID_DEVICE_HEADER_MAP,
  DEVICE_FIELD_INITIAL_FILTERS,
};
