import React from 'react';
import { MdLocationOn } from 'react-icons/md'; // cspell: disable-line

import type { IRoute } from 'types/route';
import Track from './TrackingDetails';

const trackingRoute:IRoute[] = [{
  component: <Track />,
  icon: <MdLocationOn />, // cspell: disable-line
  label: 'Tracking Details',
  path: '/Track/:recordId',
  hideSidebarLink: true,
}];

export default trackingRoute;
