// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { FormGroup, Input, Label } from 'reactstrap';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import Loading from 'components/shared/Loading/Loading';
import { useGetTakParams, usePatchShowTrackedRecords } from 'apiHooks/Tak.Hook';
import EditClientKeyModal from './EditClientKeyModal';
import EditIpAddressModal from './EditIpAddressModal';

export default function ShowTakIntegration() {
  const {
    isLoading,
    isError,
    data,
    error,
  } = useGetTakParams();

  const patchShowTrackedRecords = usePatchShowTrackedRecords();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>{error as string}</div>;
  }

  return (
    <div className="d-flex justify-content-center bg-gray gap-3">
      <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
        <div className="d-flex flex-column">
          <small className="text-nowrap align-items-end text-gray-dark">
            TAKServer IP Address:
          </small>
          <EditIpAddressModal
            ipAddress={data.ipAddress || ''}
            ipHasFailed={data.ipHasFailed || false}
          />
        </div>
        <div className="d-flex flex-column">
          <small className="text-nowrap align-items-end text-gray-dark">
            Client Key:
          </small>
          <EditClientKeyModal
            hasKey={data.hasKey || false}
            keyHasFailed={data.keyHasFailed || false}
          />
        </div>
        <div className="d-flex flex-column">
          <FormGroup switch className="mt-2">
            <Input
              type="switch"
              role="switch"
              checked={data.showOnlyTrackedCargo}
              onClick={() => patchShowTrackedRecords(!data.showOnlyTrackedCargo)}
            />
            <Label check>Show only tracked records</Label>
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
