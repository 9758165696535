import React from 'react';
import {
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { usePatchTimeout } from 'apiHooks/Details.Hook';

interface IEditTimeoutModal {
  serial: string,
  timeoutIntervals: number,
}

function EditTimeoutModal({ serial, timeoutIntervals }: IEditTimeoutModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpVal, setTmpVal] = React.useState(timeoutIntervals);

  const patch = usePatchTimeout();

  const toggle = () => setModalOpen(!modalOpen);
  const resetState = () => setTmpVal(timeoutIntervals);

  const onSubmit = () => patch(serial, tmpVal)
    .then(() => {
      toggle();
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{timeoutIntervals}</span>
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <AtlasModal
        title="Update Timeout Intervals"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={timeoutIntervals !== tmpVal}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="inputTimeoutIntervals"
            autoComplete="off"
            defaultValue={tmpVal}
            onChange={(event) => setTmpVal(Number.parseInt(event.target.value, 10))}
            placeholder=" "
          />
          <label htmlFor="inputTimeoutIntervals">
            Timeout Intervals
          </label>
        </div>
      </AtlasModal>
    </>
  );
}

export default EditTimeoutModal;
