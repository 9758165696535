import React from 'react';
import { TileLayer } from 'react-leaflet';

function GoogleTileLayer() {
  return (
    <TileLayer
      noWrap={false}
      url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
    />
  );
}

export default GoogleTileLayer;
