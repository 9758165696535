// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import 'leaflet-canvas-marker';
import L, { latLngBounds, type LatLngExpression } from 'leaflet';
import {
  LayerGroup,
  Marker,
  Tooltip,
  useMap,
} from 'react-leaflet';
import { renderToString } from 'react-dom/server';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { FormattedLocation, LatLon, PositioningMethod } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { formatDate, getTextColorTOHigh, getTextColorTOLow } from 'utils/Helpers';

import atlasMoveGnss from 'assets/img/atlas-move-gnss.png';
import atlasMoveGeo from 'assets/img/atlas-move-geo.png';
import atlasStatGnss from 'assets/img/atlas-stat-gnss.png';
import atlasStatGeo from 'assets/img/atlas-stat-geo.png';

import fileNameMap from './rasterizedImages.ts';

import './leaflet.css';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface IDeviceBannerParams {
  serial: string,
  showLink: boolean,
}

interface ILastReportParams {
  reportTime: string,
  battery: number,
}

interface IPositionParams {
  posMethod: PositioningMethod,
  uncertainty: number,
}

interface IMarkerParams {
  loc: FormattedLocation,
  serial: string | undefined,
  idx?: number,
  numPoints?: number,
}

interface IMarkerAddParams {
  locations: FormattedLocation[],
  useColor?: boolean | undefined,
}

// ////////////////////////////////////////////////////////////////////////////
// General Config
// ////////////////////////////////////////////////////////////////////////////
const ICON_SIZE = [40, 40] as L.PointExpression;
const ICON_ANCHOR = [20, 20] as L.PointExpression;

// ////////////////////////////////////////////////////////////////////////////
// Colored Icons
// ////////////////////////////////////////////////////////////////////////////

function determineColoredIcon(
  moving: boolean,
  posMethod: PositioningMethod,
  idx: number,
  numPoints: number,
) {
  // Index, scaled to 0-255 range
  const scaled = (idx / numPoints) * 255;

  // Round to nearest multiple of 25
  const step = Math.round(scaled / 25) * 25;

  const move = (moving ? 'move' : 'stat');
  const pos = (posMethod === 'GNSS' ? 'gnss' : 'geo');

  const key = `atlas-${move}-${pos}-${step}`;
  const iconUrl = fileNameMap[key] as string;

  return L.icon({
    iconUrl,
    iconRetinaUrl: iconUrl,
    iconSize: ICON_SIZE,
    iconAnchor: ICON_ANCHOR,
  });
}

// ////////////////////////////////////////////////////////////////////////////
// B/W Icons
// ////////////////////////////////////////////////////////////////////////////

const atlasMoveGnssIcon = L.icon({
  iconUrl: atlasMoveGnss,
  iconRetinaUrl: atlasMoveGnss,
  iconSize: ICON_SIZE,
  iconAnchor: ICON_ANCHOR,
});

const atlasMoveGeoIcon = L.icon({
  iconUrl: atlasMoveGeo,
  iconRetinaUrl: atlasMoveGeo,
  iconSize: ICON_SIZE,
  iconAnchor: ICON_ANCHOR,
});

const atlasStatGnssIcon = L.icon({
  iconUrl: atlasStatGnss,
  iconRetinaUrl: atlasStatGnss,
  iconSize: ICON_SIZE,
  iconAnchor: ICON_ANCHOR,
});

const atlasStatGeoIcon = L.icon({
  iconUrl: atlasStatGeo,
  iconRetinaUrl: atlasStatGeo,
  iconSize: ICON_SIZE,
  iconAnchor: ICON_ANCHOR,
});

function determineIcon(moving: boolean, posMethod: PositioningMethod) {
  if (moving && posMethod === 'GNSS') {
    return atlasMoveGnssIcon;
  }

  if (moving) {
    return atlasMoveGeoIcon;
  }

  if (posMethod === 'GNSS') {
    return atlasStatGnssIcon;
  }

  return atlasStatGeoIcon;
}

// ////////////////////////////////////////////////////////////////////////////
// Components
// ////////////////////////////////////////////////////////////////////////////

function DeviceBanner({ serial, showLink }: IDeviceBannerParams) {
  const DisplaySerial = showLink ? <a href={`/Device/${serial}`}>{serial}</a> : <span>{serial}</span>;

  return (
    <p>
      Device:
      {' '}
      {DisplaySerial}
    </p>
  );
}

function LastReportEntry({ reportTime, battery }: ILastReportParams) {
  return (
    <div>
      Check-in Time:
      {' '}
      {formatDate(reportTime)}
      {' ('}
      <span className={`${getTextColorTOHigh(battery, 50, 20)}`}>
        {battery}
        %
      </span>
      {' battery)'}
    </div>
  );
}

function PositionEntry({ posMethod, uncertainty }: IPositionParams) {
  return (
    <div>
      Accuracy:
      {' '}
      <span className={`${getTextColorTOLow(uncertainty, 1500, 100)}`}>
        {uncertainty}
        {' '}
        m
      </span>
      {' '}
      determined by
      {' '}
      {posMethod}
    </div>
  );
}

function LocationEntry({ location }: { location: LatLon }) {
  return (
    <div>
      Location:
      {' '}
      {location.latitude}
      {', '}
      {location.longitude}
    </div>
  );
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
export function toLatLng(x: FormattedLocation): LatLngExpression {
  return {
    lat: x.location.latitude,
    lng: x.location.longitude,
  };
}

export function AtlasMarker(
  {
    loc: {
      locationId,
      location,
      battery,
      posMethod,
      reportTime,
      uncertainty,
      moving,
    },
    serial,
    idx,
    numPoints,
  }: IMarkerParams,
) {
  const useColoredIcon = (idx ?? numPoints ?? null) !== null;
  const icon = (
    useColoredIcon
      ? determineColoredIcon(moving, posMethod, idx as number, numPoints as number)
      : determineIcon(moving, posMethod)
  );

  return (
    <Marker
      key={locationId}
      position={{ lat: location.latitude, lng: location.longitude }}
      icon={icon}
    >
      <Tooltip>
        <div>
          Device:
          {serial || 'Unknown'}
        </div>
        <LastReportEntry reportTime={reportTime} battery={battery} />
        <PositionEntry posMethod={posMethod} uncertainty={uncertainty} />
      </Tooltip>
    </Marker>
  );
}

export function AddMarkers({ locations, useColor }: IMarkerAddParams) {
  const leafletMap = useMap();
  const [localLocations, setLocalLocations] = useState<FormattedLocation[]>([]);

  useEffect(() => {
    if (
      locations
      && locations[locations.length - 1] !== localLocations[localLocations.length - 1]
    ) {
      const coords = locations.map((x) => toLatLng(x));

      setLocalLocations(locations);
      const coordinateBounds = latLngBounds(coords);
      leafletMap.fitBounds(coordinateBounds, { padding: [50, 50] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  if (!localLocations?.length) {
    return <div />;
  }

  return (
    <LayerGroup>
      { locations.map((loc, idx) => (
        useColor
          ? (
            <AtlasMarker
              key={loc.locationId}
              loc={loc}
              serial={loc.serial}
              idx={idx}
              numPoints={locations.length}
            />
          )
          : (
            <AtlasMarker
              key={loc.locationId}
              loc={loc}
              serial={loc.serial}
            />
          )
      )) }
    </LayerGroup>
  );
}

export function LeafletCanvasMarkers({ locations, useColor }: IMarkerAddParams) {
  const map = useMap();

  useEffect(() => {
    if (!map || locations.length === 0) return;

    const coords = locations.map((x) => toLatLng(x));
    const coordinateBounds = latLngBounds(coords);
    map.fitBounds(coordinateBounds, { padding: [50, 50] });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ciLayer = (L as any).canvasIconLayer({}).addTo(map);

    const markers = locations.map((loc, idx) => {
      const icon = (
        useColor
          ? determineColoredIcon(loc.moving, loc.posMethod, idx, locations.length)
          : determineIcon(loc.moving, loc.posMethod)
      );

      return L.marker(
        [loc.location.latitude, loc.location.longitude],
        { icon },
      ).bindPopup(`${renderToString(<DeviceBanner serial={loc.serial} showLink={!useColor} />)}
        ${renderToString(<LastReportEntry reportTime={loc.reportTime} battery={loc.battery} />)}
        ${renderToString(<LocationEntry location={loc.location} />)}
        ${renderToString(<PositionEntry posMethod={loc.posMethod} uncertainty={loc.uncertainty} />)}
      `);
    });

    ciLayer.addLayers(markers);
  }, [map, locations, useColor]);

  return null;
}
