// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { getTextColorTOHigh } from 'utils/Helpers';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridBatteryCellRenderer(
  { data }: ICellRendererParams<{ battery: number }>,
) {
  return data?.battery ? (
    <div className={`${getTextColorTOHigh(data.battery, 50, 20)}`}>
      {data.battery}
      %
    </div>
  ) : null;
}

export default AgGridBatteryCellRenderer;
