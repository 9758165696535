// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  MapContainer,
} from 'react-leaflet';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { FormattedLocation } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { MAP_DEFAULT_LOCATION } from 'utils/Constants';
import CustomMapZoomControls from 'components/leafletMapUtils/CustomMapZoomControls';
import GoogleTileLayer from 'components/leafletMapUtils/GoogleTileLayer';
import { LeafletCanvasMarkers, toLatLng } from 'components/leafletMapUtils/markers';

// eslint-disable-next-line import/order
import styles from './AtlasMap.module.css';

// ////////////////////////////////////////////////////////////////////////////
// Styles
// ////////////////////////////////////////////////////////////////////////////
import 'leaflet/dist/leaflet.css';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface IAtlasMapParams {
  locations: FormattedLocation[],
  padBottom?: boolean,
  useColor?: boolean,
  showEmptyMap?: boolean,
  fixedSize?: boolean,
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AtlasMap(
  {
    locations,
    padBottom,
    useColor,
    showEmptyMap,
    fixedSize,
  }: IAtlasMapParams,
) {
  const [localLocations, setLocalLocations] = useState(locations);
  const [coords, setCoords] = useState(locations.map((x) => toLatLng(x)));

  useEffect(() => {
    const c = locations.map((x) => toLatLng(x));
    setLocalLocations(locations);
    setCoords(c);
  }, [locations]);

  if (localLocations.length === 0 && !showEmptyMap) {
    return <p>No location data to show</p>;
  }

  return (
    <div className={`d-flex w-100 ${styles[fixedSize ? 'atlas-map-fixed' : 'atlas-map-pct']} ${padBottom ? 'pb-2' : ''}`}>
      <MapContainer className={`d-block w-100 h-200em ${styles['dashboard-map']}`} center={MAP_DEFAULT_LOCATION} zoom={8} zoomControl={false}>
        <GoogleTileLayer />

        {/* <AddMarkers locations={localLocations} useColor={useColor} /> */}

        <LeafletCanvasMarkers locations={localLocations} useColor={useColor} />

        <CustomMapZoomControls
          coordinates={coords}
          includeAutoZoom
        />
      </MapContainer>
    </div>
  );
}

export default AtlasMap;
