import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/filter';
import {
  FILTER_TYPES,
} from 'utils/Constants';
import type { UserAdminField } from './UserAdminTableColumns';

const USER_ADMIN_INITIAL_FILTERS: ICustomFilter<UserAdminField> = {
  givenName: { // cSpell: disable-line
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  familyName: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  email: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  claims: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, true],
    isHidden: false,
  },
  active: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, true],
    isHidden: false,
  },
};

const UserAdminFilterAtom = atomWithReset<ICustomFilter<UserAdminField>>(
  USER_ADMIN_INITIAL_FILTERS,
);

const UserAdminInitialFilterAtom = atomWithReset<ICustomFilter<UserAdminField>>(
  USER_ADMIN_INITIAL_FILTERS,
);

export {
  UserAdminFilterAtom,
  UserAdminInitialFilterAtom,
};
