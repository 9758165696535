// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { formatDate } from 'utils/Helpers';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridReportTimeCellRenderer(
  { data }: ICellRendererParams<{ reportTime: string }>,
) {
  return data?.reportTime ? (
    <div>
      {formatDate(data?.reportTime)}
    </div>
  ) : null;
}

export default AgGridReportTimeCellRenderer;
