import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function TogglePasswordIcon({ showPassword }: { showPassword: boolean }) {
  return (
    showPassword ? <FaEyeSlash className="h4 mb-0" /> : <FaEye className="h4 mb-0" />
  );
}

export default TogglePasswordIcon;
