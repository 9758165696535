// /////////////////////////////////////////////////////////////////////////////
// React Imports
// /////////////////////////////////////////////////////////////////////////////
import React from 'react';

// /////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// /////////////////////////////////////////////////////////////////////////////
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { useForm } from 'react-hook-form';

// /////////////////////////////////////////////////////////////////////////////
// Local Imports
// /////////////////////////////////////////////////////////////////////////////
import TogglePasswordIcon from 'components/fields/TogglePasswordIcon';
import { usePostUser } from 'apiHooks/Users.Hook';
import type { INewUser } from 'types/user';
import { userOrganizationName, useRestrictAccess } from 'utils/Helpers';
import type { IZxcvbn } from 'types/zxcvbn';
import { AtlasModalHeader } from 'components/shared/AtlasModal/AtlasModal';

// /////////////////////////////////////////////////////////////////////////////
// Styles
// /////////////////////////////////////////////////////////////////////////////
import styles from './AddNewUser.module.css';

// /////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// /////////////////////////////////////////////////////////////////////////////
interface IAddUserErrors {
  formErrors: string[],
  addUserErrors: string | undefined,
}

// /////////////////////////////////////////////////////////////////////////////
// Config
// /////////////////////////////////////////////////////////////////////////////
const passwordVisibilityClasses = 'mb-3 bg-white border-start-0 rounded-end toggle-pw-visibility';
const errorClass = `is-invalid ${styles['no-blur'] || ''}`;

// /////////////////////////////////////////////////////////////////////////////
// Exports
// /////////////////////////////////////////////////////////////////////////////
function AddUserErrors({ formErrors, addUserErrors }: IAddUserErrors) {
  if (formErrors.length === 0 && !addUserErrors) {
    return <div />;
  }

  if (formErrors.length === 1 && formErrors[0] === 'confirmPassword') {
    return <Alert className="mb-0 text-center" color="danger">Passwords do not match</Alert>;
  }

  if (addUserErrors) {
    try {
      const { errorMessage } = JSON.parse(addUserErrors) as Record<string, IZxcvbn>;
      const zxcvbnResponse = errorMessage as IZxcvbn;
      return (
        <Alert className="mb-0 text-center" color="danger">
          {`${zxcvbnResponse.warning}. ${zxcvbnResponse.suggestions.join(' ')}`}
        </Alert>
      );
    } catch {
      try {
        const { errorMessage } = JSON.parse(addUserErrors) as Record<string, string>;
        return (
          <Alert className="mb-0 text-center" color="danger">
            {errorMessage}
          </Alert>
        );
      } catch {
        return <Alert className="mb-0 text-center" color="danger">{addUserErrors.replaceAll('"', '')}</Alert>;
      }
    }
  }

  return <div />;
}

function AddUserModal() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [addUserErrors, setAddUsersErrors] = React.useState('');
  const postUser = usePostUser();

  const toggle = () => setModalOpen(!modalOpen);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<INewUser>({
    mode: 'onBlur',
    defaultValues: {
      claims: [],
    },
  });

  const restrictUpdates = useRestrictAccess('admin');

  const givenNameFormRegistration = register('givenName', { required: true });
  const familyNameFormRegistration = register('familyName', { required: true });
  const emailFormRegistration = register('email', { required: true });
  const passwordFormRegistration = register('password', { required: true });
  const confirmPasswordFormRegistration = register(
    'confirmPassword',
    {
      required: true,
      validate: (confirmPassword: string | undefined) => (watch('password') === confirmPassword),
    },
  );
  const adminFormRegistration = register('claims');

  const onSubmit = (formData: INewUser) => {
    postUser(formData).then(toggle).catch(async (error: Response) => {
      const errorText = await error.text();
      setAddUsersErrors(errorText);
    });
  };

  return (
    <>
      <Button disabled={restrictUpdates} onClick={toggle}>Add User</Button>
      <Modal isOpen={modalOpen} toggle={toggle}>
        <Form
          className="bg-white container-lg g-0 rounded"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AtlasModalHeader toggle={toggle}>
            Add user to
            {' '}
            { userOrganizationName() }
          </AtlasModalHeader>
          <ModalBody>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['givenName'] ? errorClass : '')}`} // cSpell: disable-line
                id="inputGivenNameInput"
                placeholder=" "
                autoComplete="off"
                onChange={givenNameFormRegistration.onChange}
                onBlur={givenNameFormRegistration.onBlur}
                name={givenNameFormRegistration.name}
                ref={givenNameFormRegistration.ref}
              />
              <Label htmlFor="inputGivenNameInput">First/Given Name</Label>
            </FormGroup>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['familyName'] ? errorClass : '')}`} // cSpell: disable-line
                id="inputFamilyNameInput"
                autoComplete="off"
                placeholder=" "
                onChange={familyNameFormRegistration.onChange}
                onBlur={familyNameFormRegistration.onBlur}
                name={familyNameFormRegistration.name}
                ref={familyNameFormRegistration.ref}
              />
              <Label htmlFor="inputFamilyNameInput">Last/Family Name</Label>
            </FormGroup>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors.email ? errorClass : '')}`}
                id="inputEmailInput"
                autoComplete="off"
                placeholder=" "
                onChange={emailFormRegistration.onChange}
                onBlur={emailFormRegistration.onBlur}
                name={emailFormRegistration.name}
                ref={emailFormRegistration.ref}
              />
              <Label htmlFor="inputEmailInput">Email address</Label>
            </FormGroup>
            <InputGroup size="sm" className="mb-3">
              <FormGroup floating className="shadow-sm">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control border-end-0 ${(errors.password ? errorClass : '')}`}
                  autoComplete="new-password"
                  placeholder=" "
                  id="inputPassword"
                  onChange={passwordFormRegistration.onChange}
                  onBlur={passwordFormRegistration.onBlur}
                  name={passwordFormRegistration.name}
                  ref={passwordFormRegistration.ref}
                />
                <Label htmlFor="inputPassword">Password</Label>
              </FormGroup>
              <InputGroupText
                className={`${passwordVisibilityClasses}  ${(errors.password ? 'border-danger' : '')}`}
                onClick={() => setShowPassword(!showPassword)}
                title="Show/Hide password"
              >
                <TogglePasswordIcon showPassword={showPassword} />
              </InputGroupText>
            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <FormGroup floating className="shadow-sm">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`form-control border-end-0 ${(errors.confirmPassword || errors.password ? errorClass : '')}`}
                  autoComplete="new-password"
                  id="inputConfirmPassword"
                  placeholder=" "
                  onChange={confirmPasswordFormRegistration.onChange}
                  onBlur={confirmPasswordFormRegistration.onBlur}
                  name={confirmPasswordFormRegistration.name}
                  ref={confirmPasswordFormRegistration.ref}
                />
                <Label htmlFor="inputConfirmPassword">Confirm Password</Label>
              </FormGroup>
              <InputGroupText
                className={`${passwordVisibilityClasses} ${(errors.confirmPassword || errors.password ? 'border-danger' : '')}`}
                onClick={() => setShowPassword(!showPassword)}
                title="Show/Hide password"
              >
                <TogglePasswordIcon showPassword={showPassword} />
              </InputGroupText>
            </InputGroup>
            <div className="d-flex justify-content-between mb-3">
              <FormGroup
                check
                inline
              >
                <Label check>
                  <input
                    type="checkbox"
                    value="admin"
                    onChange={adminFormRegistration.onChange}
                    onBlur={adminFormRegistration.onBlur}
                    name={adminFormRegistration.name}
                    ref={adminFormRegistration.ref}
                  />
                  Admin
                </Label>
              </FormGroup>
            </div>
            <AddUserErrors
              formErrors={Object.keys(errors)}
              addUserErrors={addUserErrors}
            />
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <input
              className="btn btn-primary text-dark"
              disabled={(!errors || (Object.keys(errors)).length > 0)}
              type="submit"
              value="Add User"
            />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default AddUserModal;
