/**
 * Format Date String
 *
 * @param dateStr ISO Date string
 * @returns
 */
const formatDate = (dateStr: string, showTz = false) => {
  if (!dateStr) {
    return '';
  }

  try {
    return new Date(dateStr).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
      timeZoneName: (showTz ? 'short' : undefined),
    });
  } catch {
    return '';
  }
};

export default formatDate;
