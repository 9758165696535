/**
 * Generic HTTP POST wrapper (Stonewall Defense API HTTP POST without authorization token)
 *
 * XXX: wait until backend updated to return JSON before merging into swd-ui-utils.
 *
 * @param urlPath URL Path of API call
 * @param data data
 *
 * @returns Promise
 */
const httpPost = (
  urlPath: string,
  data?: Record<string, string>,
) => new Promise((resolve, reject) => {
  try {
    const postParameters = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    fetch(urlPath, postParameters).then(async (response) => {
      if (response.ok) {
        const responseText = await response.text();
        resolve(responseText);
      }
      reject(response);
    }).catch((error) => reject(error));
  } catch (error) {
    reject(error);
  }
});

export default httpPost;
