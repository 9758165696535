import React from 'react';
import type { ButtonProps } from 'reactstrap';
import styles from './FilterButton.module.css';

/* eslint-disable react/jsx-props-no-spreading */
function FilterButton(props: ButtonProps) {
  const { active, ...remainingProps } = props;
  const { children, className } = remainingProps;

  return (
    <button
      type="button"
      {...remainingProps}
      className={`m-0 ${styles[
        active
          ? 'filter-button-pressed'
          : 'filter-button'
      ]} ${className || ''}`}
    >
      {children}
    </button>
  );
}
/* eslint-enable react/jsx-props-no-spreading */

export default FilterButton;
