import type { WritableAtom } from 'jotai';
import { atomWithReset, type RESET } from 'jotai/utils';
import type { ColDef } from '@ag-grid-community/core';

import type { ICustomFilter } from 'types/filter';

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

type WritableFilterAtom = WritableAtom<
ICustomFilter, [SetStateActionWithReset<ICustomFilter>], void
>;

type WritableColDefsAtom = WritableAtom<
ColDef[], [SetStateActionWithReset<ColDef[]>], void
>;

export interface IRhinoUserCacheAtom {
  UserAdminAgGridColDefsAtom: WritableColDefsAtom | undefined,
  UserAdminFilterAtom: WritableFilterAtom | undefined,
  UserAdminInitialFilterAtom: WritableFilterAtom | undefined,
}

const AtlasUserCacheAtom = atomWithReset<IRhinoUserCacheAtom>({
  UserAdminAgGridColDefsAtom: undefined,
  UserAdminFilterAtom: undefined,
  UserAdminInitialFilterAtom: undefined,
});

export default AtlasUserCacheAtom;
