// /////////////////////////////////////////////////////////////////////////////
// React Imports
// /////////////////////////////////////////////////////////////////////////////
import React from 'react';

// /////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// /////////////////////////////////////////////////////////////////////////////
import { CSVLink } from 'react-csv';
import type { LabelKeyObject } from 'react-csv/lib/core';
import { MdOutlineTableChart } from 'react-icons/md';
import { Button } from 'reactstrap';

// /////////////////////////////////////////////////////////////////////////////
// SWD Imports
// /////////////////////////////////////////////////////////////////////////////
import type { FormattedLocation } from '@stonewall-defense/atlas-common';

// /////////////////////////////////////////////////////////////////////////////
// Types
// /////////////////////////////////////////////////////////////////////////////
interface ILocationsCsvProps {
  locations: FormattedLocation[],
  filename?: string,
}

// /////////////////////////////////////////////////////////////////////////////
// Config
// /////////////////////////////////////////////////////////////////////////////
const LOCATIONS_CSV_HEADERS: LabelKeyObject[] = [
  {
    label: 'Record ID',
    key: 'locationId',
  },
  {
    label: 'Device Serial',
    key: 'serial',
  },
  {
    label: 'Report Time (UTC)',
    key: 'reportTime',
  },
  {
    label: 'Latitude',
    key: 'latitude',
  },
  {
    label: 'Longitude',
    key: 'longitude',
  },
  {
    label: 'Altitude (m HAE)',
    key: 'altitude',
  },
  {
    label: 'Uncertainty (m)',
    key: 'uncertainty',
  },
  {
    label: 'Battery (%)',
    key: 'battery',
  },
  {
    label: 'Positioning Method',
    key: 'posMethod',
  },
  {
    label: 'Moving',
    key: 'moving',
  },
  {
    label: 'Geolocation',
    key: 'usedGeolocation',
  },
  {
    label: 'AGNSS',
    key: 'usedAgnss',
  },
];

// /////////////////////////////////////////////////////////////////////////////
// Exports
// /////////////////////////////////////////////////////////////////////////////
export default function LocationsCsv({ locations, filename }: ILocationsCsvProps) {
  const fmt = locations.map((l) => ({
    ...l,
    latitude: l.location.latitude,
    longitude: l.location.longitude,
  }));

  return (
    <Button
      className="p-2 px-3"
      title="Export"
    >
      <CSVLink
        headers={LOCATIONS_CSV_HEADERS}
        data={fmt}
        filename={filename}
        style={{ color: 'inherit', textDecoration: 'inherit' }}
      >
        Export
        {' '}
        <MdOutlineTableChart />
      </CSVLink>
    </Button>
  );
}
