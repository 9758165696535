// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import type { GridApi, GridReadyEvent, IRowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useAtom } from 'jotai';
import { isEqual } from 'lodash-es';
import { Button, Input } from 'reactstrap';
import { MdFilterAlt } from 'react-icons/md';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { CurrentTrackingRecord, DeviceCore, FormattedLocation } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import type { AgGridTracking } from 'types/track';
import type { IColumnFilter } from 'types/filter';
import Filter, { isFilterApplied, passesTextFilter } from 'components/shared/Filter/Filter';
import { FILTER_TYPES } from 'utils/Constants';
import { mapLocations } from 'utils/Helpers';

import { TrackingFilterAtom, TrackingInitialFilterAtom } from './TrackingFilter.store';
import { AG_GRID_TRACKING_FIELDS } from './TrackingTableColumns';
import TrackingAgGridColDefsAtom from './TrackingAgGridColDefs.store';
import AddRecordModal from './AddRecordModal';
import AssociateTrackerModal from './AssociateTrackerModal';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface ITrackingTableParams {
  locations: FormattedLocation[] | undefined,
  trackingData: CurrentTrackingRecord[] | undefined,
  deviceData: DeviceCore[] | undefined,
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function TrackingTable({ locations, trackingData, deviceData }: ITrackingTableParams) {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);
  const [rows, setRows] = React.useState<AgGridTracking[]>([]);
  const [gridApi, setGridApi] = React.useState<GridApi | undefined>();
  const [columnDefs] = useAtom(TrackingAgGridColDefsAtom);
  const [appliedFilter] = useAtom(TrackingFilterAtom);
  const [deviceInitialFilter] = useAtom(TrackingInitialFilterAtom);

  useEffect(() => {
    if (!(!!locations && !!trackingData)) return;
    const locationMap = mapLocations(locations);

    const agGridTracking = trackingData.map<AgGridTracking>((record) => {
      const location = (record.serial ? locationMap[record.serial] : null);

      return {
        ...record,

        reportTime: location?.reportTime ?? null,
        location: location?.location ?? null,
        uncertainty: location?.uncertainty ?? null,
        battery: location?.battery ?? null,
        posMethod: location?.posMethod ?? null,
        moving: location?.moving ?? null,
      };
    });

    setRows(agGridTracking);
    gridApi?.setRowData(agGridTracking);
  }, [gridApi, locations, trackingData]);

  if (!locations || !trackingData || !deviceData) {
    return <p>No tracking data to show</p>;
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const externalFilter = (node: IRowNode<AgGridTracking>) => (
    AG_GRID_TRACKING_FIELDS.reduce((isDisplayed, key) => {
      const agGridDeviceKey = key;
      const colFilter: IColumnFilter | undefined = appliedFilter[key];
      const nodeData = node.data;

      if (!isDisplayed || colFilter === undefined || colFilter.isHidden || nodeData === undefined) {
        return isDisplayed;
      }

      if (colFilter.filterType === FILTER_TYPES.TEXT) {
        return passesTextFilter(colFilter, nodeData[agGridDeviceKey] as string);
      }

      return isDisplayed;
    }, true)
  );

  const onSearchQueryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    gridApi?.setQuickFilter(e.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-between bg-dark bg-gradient rounded-top p-3">
        <div className="d-flex gap-3">
          <AddRecordModal />
          <AssociateTrackerModal
            records={trackingData}
            devices={deviceData}
          />
        </div>

        <Input
          className="w-50"
          placeholder="Search..."
          onChange={onSearchQueryChange}
        />

        <div className="d-flex gap-3">
          <Button
            title="Filter Records"
            className="align-self-center border-0 bg-transparent"
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            <MdFilterAlt className={(isEqual(appliedFilter, deviceInitialFilter) ? 'text-white' : 'text-primary')} />
          </Button>
        </div>
      </div>
      <div className="ag-theme-material w-100 h-100">
        <Filter filterName="Tracking" isOpened={showFilterMenu} />

        <AgGridReact
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={columnDefs}
          animateRows
          maintainColumnOrder
          isExternalFilterPresent={isFilterApplied}
          doesExternalFilterPass={externalFilter}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
        />
      </div>
    </>
  );
}

export default TrackingTable;
