const formatQueryParams = (params?: Record<string, string | undefined>) => (
  params
    ? `?${(
      Object.keys(params).map((key) => (
        params[key] && `${key}=${params[key]}`
      ))
        .filter(Boolean)
        .join('&')
    )}`
    : ''
);

export default formatQueryParams;
