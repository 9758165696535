import React from 'react';
import { useCookies } from 'react-cookie';
import {
  Alert,
  Form,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupText,
  Card,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdMail, MdPassword } from 'react-icons/md';

import { decodeJwt } from '@stonewall-defense/swd-ui-utils';
import TogglePasswordIcon from 'components/fields/TogglePasswordIcon';
import { COOKIE_NAME } from 'utils/Constants';
import { httpPost } from 'utils/Helpers';

export interface ILogin {
  email: string,
  password: string,
}

const notAuthorizedMessage = 'No such user is authorized.';

// XXX: react-hook-form does not play well with reactstrap Input.  Wait for Reactstrap-v10

function Login() {
  const navigate = useNavigate();
  const [, setCookie] = useCookies([COOKIE_NAME]);
  const [loginError, setLoginError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const { register, handleSubmit, formState } = useForm<ILogin>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const displayLoginError = (
    loginError === notAuthorizedMessage
      ? `${notAuthorizedMessage} If you are registered, please contact your administrator.`
      : loginError
  );

  const emailFormRegistration = register('email', { required: true });
  const passwordFormRegistration = register('password', { required: true });

  const onSubmit = (formData: ILogin) => {
    httpPost('/auth/v1/login', formData as unknown as Record<string, string>).then((data) => {
      if (data && decodeJwt(String(data))) {
        setCookie('ATLAS', data, { path: '/' });
      } else {
        setLoginError('An unknown error occurred. Please try again.');
      }
    })
      .catch(async (error: Response) => {
        const errorText = await error.text();
        const errorObj = JSON.parse(errorText) as { errorMessage: string; };
        const { errorMessage } = errorObj;
        setLoginError(errorMessage);
      });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <Card className="col-lg-4 col-12 rounded">
        <CardBody>
          <Form className="bg-white rounded" onSubmit={handleSubmit(onSubmit)}>
            <InputGroup size="sm" className="mb-3">
              <InputGroupText className="mb-3 shadow-sm bg-secondary text-light">
                <MdMail className="h4 mb-0" />
              </InputGroupText>
              <FormGroup floating className="shadow-sm">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  autoComplete="username"
                  placeholder=" "
                  onChange={emailFormRegistration.onChange}
                  onBlur={emailFormRegistration.onBlur}
                  name={emailFormRegistration.name}
                  ref={emailFormRegistration.ref}
                />
                <Label htmlFor="floatingInput">Email address</Label>
                <div>
                  {formState.errors.email?.message}
                </div>
              </FormGroup>

            </InputGroup>
            <InputGroup size="sm" className="mb-3">
              <InputGroupText className="mb-3 shadow-sm bg-secondary bg-gradient text-light">
                <MdPassword className="h4 mb-0" />
              </InputGroupText>
              <FormGroup floating className="shadow-sm">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control border-end-0"
                  autoComplete="current-password"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={passwordFormRegistration.onChange}
                  onBlur={passwordFormRegistration.onBlur}
                  name={passwordFormRegistration.name}
                  ref={passwordFormRegistration.ref}
                />
                <Label htmlFor="floatingPassword">Password</Label>
              </FormGroup>
              <InputGroupText
                className="mb-3 bg-white border-start-0 rounded-end toggle-pw-visibility"
                onClick={() => setShowPassword(!showPassword)}
                title="Show/Hide password"
              >
                <TogglePasswordIcon showPassword={showPassword} />
              </InputGroupText>
              <div>
                {formState.errors.password?.message}
              </div>
            </InputGroup>
            {
              loginError && <Alert color="danger">{displayLoginError}</Alert>
            }
            <div className="d-flex justify-content-center">
              <input
                className={`btn btn-primary px-4 ${formState.isValid ? 'text-white' : 'text-dark'}`}
                type="submit"
                disabled={!formState.isValid}
                value="Log In"
              />
            </div>
          </Form>
        </CardBody>
        <CardFooter className="d-flex justify-content-between bg-secondary bg-gradient">
          <Button color="link" onClick={() => navigate('/ForgotPassword')}>Forgot Password?</Button>
          <Button color="link" onClick={() => navigate('/Register')}>Register</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Login;
