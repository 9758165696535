// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import type { ColDef } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import AgGridDeviceLinkCellRenderer from 'components/agGridRenderers/AgGridDeviceLinkCellRenderer';
import AgGridReportTimeCellRenderer from 'components/agGridRenderers/AgGridReportTimeCellRenderer';
import AgGridLocationCellRenderer from 'components/agGridRenderers/AgGridLocationCellRenderer';
import AgGridPosMethodCellRenderer from 'components/agGridRenderers/AgGridPosMethodCellRenderer';
import AgGridBatteryCellRenderer from 'components/agGridRenderers/AgGridBatteryCellRenderer';
import AgGridYesNoCellRenderer from 'components/agGridRenderers/AgGridYesNoCellRenderer';
import {
  AG_GRID_DEVICE_FIELDS,
  AG_GRID_DEVICE_HEADER_MAP,
  DEVICE_FIELD_INITIAL_FILTERS,
} from './DeviceTableColumns';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
const initialColumns: ColDef[] = (
  AG_GRID_DEVICE_FIELDS.map((field) => {
    const headerName = AG_GRID_DEVICE_HEADER_MAP[field] || '';
    const initialHide = DEVICE_FIELD_INITIAL_FILTERS[field]?.isHidden || false;

    if (field === 'serial') {
      return {
        field,
        headerName,
        cellRenderer: AgGridDeviceLinkCellRenderer,
        width: 100,
        initialHide,
      };
    }

    if (field === 'reportTime') {
      return {
        field,
        headerName,
        cellRenderer: AgGridReportTimeCellRenderer,
        width: 150,
        initialHide,
      };
    }

    if (field === 'location') {
      return {
        field,
        headerName,
        cellRenderer: AgGridLocationCellRenderer,
        width: 100,
        initialHide,
      };
    }

    if (field === 'posMethod') {
      return {
        field,
        headerName,
        cellRenderer: AgGridPosMethodCellRenderer,
        width: 100,
        initialHide,
      };
    }

    if (field === 'battery') {
      return {
        field,
        headerName,
        cellRenderer: AgGridBatteryCellRenderer,
        width: 100,
        initialHide,
      };
    }

    if (field === 'moving') {
      return {
        field,
        headerName,
        cellRenderer: AgGridYesNoCellRenderer,
        width: 100,
        initialHide,
      };
    }

    return {
      field,
      headerName,
    };
  })
);

const DeviceAgGridColDefsAtom = atomWithReset<ColDef[]>(initialColumns);

export default DeviceAgGridColDefsAtom;
