import React from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css'; // cSpell: disable-line
import '@fontsource/roboto';
import '@fontsource/inconsolata';

import 'assets/css/theme.css';
import UserLayout from 'components/layout/UserLayout/UserLayout';
import LoginLayout from 'components/layout/LoginLayout/LoginLayout';
import { COOKIE_NAME } from 'utils/Constants';

function App() {
  const [cookies] = useCookies([COOKIE_NAME]);
  const location = useLocation();

  return (
    cookies[COOKIE_NAME] && location.pathname !== '/PasswordReset'
      ? <UserLayout />
      : <LoginLayout />
  );
}

export default App;
