import React from 'react';
import {
  Button,
} from 'reactstrap';
import { MdDetails } from 'react-icons/md';

import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { useImei } from 'apiHooks/Details.Hook';
import Loading from 'components/shared/Loading/Loading';
import { BinaryIcon } from 'utils/Helpers';
import type { EnrichedCellModule } from '@stonewall-defense/cell-info';

function Hardware({ hardware }: { hardware: EnrichedCellModule }) {
  const {
    productName, manufacturer, antennaConfig, capabilities,
  } = hardware;

  return (
    <div>
      <p>
        {manufacturer}
        {' '}
        {productName}
      </p>
      <p>
        Antenna Config:
        {' '}
        {antennaConfig}
      </p>
      {capabilities['4G'] ? (
        <p>
          4G Capabilities
          <ul>
            <li>
              LTE Category:
              {' '}
              {capabilities['4G'].categories.join(', ')}
            </li>
            <li>
              Frequency Bands (number):
              {' '}
              {capabilities['4G'].bands.join(', ')}
            </li>
            <li>
              ITU Regions:
              {' '}
              {capabilities['4G'].ituRegions.join(', ')}
            </li>
            <li>
              Cell Regions:
              {' '}
              {capabilities['4G'].cellRegions.sort().join(', ')}
            </li>
          </ul>
        </p>
      ) : null}
      {capabilities['3G'] ? (
        <p>
          3G Capabilities
          <ul>
            <li>
              Technologoes:
              {' '}
              {capabilities['3G'].techs.join(', ')}
            </li>
            <li>
              Frequency Bands:
              {' '}
              {capabilities['3G'].bands.map((b) => `${b} MHz`).join(', ')}
            </li>
            <li>
              ITU Regions:
              {' '}
              {capabilities['3G'].ituRegions.join(', ')}
            </li>
            <li>
              Cell Regions:
              {' '}
              {capabilities['3G'].cellRegions.sort().join(', ')}
            </li>
          </ul>
        </p>
      ) : null}
      {capabilities['2G'] ? (
        <p>
          2G Capabilities
          <ul>
            <li>
              Technologoes:
              {' '}
              {capabilities['2G'].techs.join(', ')}
            </li>
            <li>
              Frequency Bands:
              {' '}
              {capabilities['2G'].bands.map((b) => `${b} MHz`).join(', ')}
            </li>
            <li>
              ITU Regions:
              {' '}
              {capabilities['2G'].ituRegions.join(', ')}
            </li>
            <li>
              Cell Regions:
              {' '}
              {capabilities['2G'].cellRegions.sort().join(', ')}
            </li>
          </ul>
        </p>
      ) : null}
    </div>
  );
}

function ImeiModal({ imei, isTech }: { imei: string, isTech: boolean }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const {
    data,
    isLoading,
    isError,
    error,
  } = useImei(imei);

  const toggle = () => setModalOpen(!modalOpen);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>{error as string}</div>;
  }

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{imei}</span>
        { isTech && (
        <Button color="link" onClick={toggle} className="py-0">
          <MdDetails />
        </Button>
        ) }
      </div>
      <AtlasModal
        title="IMEI Details"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
      >
        <div className="form-floating">
          <p>
            Value:
            {' '}
            {data.value}
          </p>
          <p>
            Valid:
            {' '}
            <BinaryIcon isGood={data.isValid} />
          </p>
          <h4>Hardware</h4>
          {data.hardware ? <Hardware hardware={data.hardware} /> : <p>Unrecognized IMEI</p>}
        </div>
      </AtlasModal>
    </>
  );
}

export default ImeiModal;
