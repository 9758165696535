import React from 'react';
import { MdOutlineMap } from 'react-icons/md'; // cspell: disable-line

import type { IRoute } from 'types/route';
import Dashboard from './Dashboard';

const trackingRoute:IRoute[] = [{
  component: <Dashboard />,
  icon: <MdOutlineMap />, // cspell: disable-line
  label: 'Dashboard',
  path: '/',
}];

export default trackingRoute;
