import React from 'react';
import { MdLocationOn } from 'react-icons/md'; // cspell: disable-line

import type { IRoute } from 'types/route';
import Track from './Track';

const trackingRoute:IRoute[] = [{
  component: <Track />,
  icon: <MdLocationOn />, // cspell: disable-line
  label: 'Track',
  path: '/Track',
}];

export default trackingRoute;
