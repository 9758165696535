// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { FormattedLocation, TrackingHistoryEntry } from '@stonewall-defense/atlas-common';
import { swdHttpPost } from '@stonewall-defense/swd-ui-utils';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { authToken } from 'utils/Helpers';
import AtlasMap from 'components/leafletMapUtils/AtlasMap';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function TrackingMap({ history }: { history: TrackingHistoryEntry[] }) {
  const [locations, setLocations] = useState<FormattedLocation[]>([]);

  useEffect(
    () => {
      const params = history.map(({ serial, validSince, validUntil }) => ({
        serial,
        startTime: validSince,
        endTime: validUntil,
      }));

      swdHttpPost('/gaia/locations/slices?filterBadPoints=true', authToken() || '', params)
        .then((data) => setLocations(data as FormattedLocation[]))
        .catch(() => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <AtlasMap locations={locations} useColor showEmptyMap />;
}

export default TrackingMap;
