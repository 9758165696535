// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  useQuery, useQueryClient,
} from '@tanstack/react-query';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { EnrichedImei, IccidInfo } from '@stonewall-defense/cell-info';
import { swdHttpGet, swdHttpPatch } from '@stonewall-defense/swd-ui-utils';
import type { DeviceDetails } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { authToken } from 'utils/Helpers';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
const useDeviceInstance = (serial: string) => useQuery({
  queryKey: ['GetDeviceInstance', serial],
  queryFn: () => swdHttpGet(`/gaia/devices/${serial}`, authToken() || '') as Promise<DeviceDetails>,
});

const useIccid = (iccid: string) => useQuery({
  queryKey: ['GetIccid', iccid],
  queryFn: () => swdHttpGet(`/gaia/iccid/${iccid}`, authToken() || '') as Promise<IccidInfo>,
});

const useImei = (imei: string) => useQuery({
  queryKey: ['GetImei', imei],
  queryFn: () => swdHttpGet(`/gaia/imei/${imei}`, authToken() || '') as Promise<EnrichedImei>,
});

const usePatchTimeout = () => {
  const queryClient = useQueryClient();

  return (serial: string, timeoutIntervals: number) => swdHttpPatch(`/gaia/devices/${serial}`, authToken() || '', { timeoutIntervals })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetDeviceInstance', serial] }));
};

const usePatchSleepMinutes = () => {
  const queryClient = useQueryClient();

  return (serial: string, minSleepMinutes: number) => swdHttpPatch(`/gaia/devices/${serial}`, authToken() || '', { minSleepMinutes })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetDeviceInstance', serial] }));
};

const usePatchSleepSeconds = () => {
  const queryClient = useQueryClient();

  return (serial: string, minSleepSeconds: number) => swdHttpPatch(`/gaia/devices/${serial}`, authToken() || '', { minSleepSeconds })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetDeviceInstance', serial] }));
};

export {
  useDeviceInstance,

  useIccid,
  useImei,

  usePatchTimeout,
  usePatchSleepMinutes,
  usePatchSleepSeconds,
};
