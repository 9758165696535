import React from 'react';
import { Col } from 'reactstrap';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import viewRoutes from 'views/ViewRoutes';
import SideBar from './Sidebar';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
]);

function UserLayout() {
  return (
    <div className="d-flex min-vh-100">
      <SideBar />
      <Col className="vh-100">
        <Routes>
          {
            viewRoutes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                element={route.component}
              />
            ))
          }
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Col>
    </div>
  );
}

export default UserLayout;
