import React from 'react';
import { MdAndroid } from 'react-icons/md'; // cspell: disable-line

import type { IRoute } from 'types/route';
import Tak from './Tak';

const trackingRoute:IRoute[] = [{
  component: <Tak />,
  icon: <MdAndroid />, // cspell: disable-line
  label: 'TAK Integration',
  path: '/Tak',
}];

export default trackingRoute;
