// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import { useSearchParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { Button } from 'reactstrap';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { FormattedLocation } from '@stonewall-defense/atlas-common';
import { swdHttpGet } from '@stonewall-defense/swd-ui-utils';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  authToken,
  formatDate,
  formatQueryParams,
  getNumber,
  getTextColorTOHigh,
  getTextColorTOLow,
  getTime,
  useRestrictAccess,
} from 'utils/Helpers';
import AtlasMap from 'components/leafletMapUtils/AtlasMap';
import LocationsTable from 'components/shared/LocationsTable/LocationsTable';
import { MdOutlineMap } from 'react-icons/md';
import LocationsCsv from 'components/shared/LocationsTable/LocationsCsv';

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
function LastCheckIn({ location }: { location: FormattedLocation }) {
  return (
    <div className="d-flex justify-content-center flex-row gap-2 p-3 border bg-light rounded-4">
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Last Report Time
        </small>
        <span>{formatDate(location.reportTime, true)}</span>
      </div>
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Relative Time
        </small>
        <span><TimeAgo date={location.reportTime} /></span>
      </div>
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Last Coordinates
        </small>
        <span>
          (
          {location.location.latitude}
          ,
          {' '}
          {location.location.longitude}
          )
        </span>
      </div>
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Accuracy
        </small>
        <span className={`${getTextColorTOLow(location.uncertainty, 1500, 100)}`}>
          {location.uncertainty}
          {' '}
          m (
          {location.posMethod}
          {' '}
          )
        </span>
      </div>
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Altitude (HAE)
        </small>
        <span>
          {location.altitude}
          {' '}
          m
        </span>
      </div>
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Battery
        </small>
        <span className={`${getTextColorTOHigh(location.battery, 50, 20)}`}>
          {location.battery}
          %
        </span>
      </div>
      <div className="d-flex flex-column px-2">
        <small className="text-nowrap align-items-end text-gray-dark">
          Moving
        </small>
        <span>{location.moving ? 'Yes' : 'No'}</span>
      </div>
    </div>
  );
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function DeviceMap({ serial }: { serial: string }) {
  const isDataScientist = !useRestrictAccess('data');
  const [searchParams, setSearchParams] = useSearchParams();

  const now = new Date();
  const [startDate, setStartDate] = useState<Date>(getTime(searchParams.get('start'), subMonths(now, 6)));
  const [endDate, setEndDate] = useState<Date>(getTime(searchParams.get('end'), now));
  const [pageSize, setPageSize] = useState<number>(getNumber(searchParams.get('limit')));
  const [locations, setLocations] = useState<FormattedLocation[]>([]);

  const tagLastSubmission = () => (`${startDate.toISOString()}-${endDate.toISOString()}-${pageSize}`);
  const [lastSubmission, setLastSubmission] = useState(tagLastSubmission());

  const [isOpen, setIsOpen] = useState(false);

  const datesValid = (s: Date, e: Date) => (s && e && s <= e);
  const canSubmit = () => (tagLastSubmission() !== lastSubmission);

  const onSubmit = (updateUrl: boolean) => {
    const s = startOfDay(startDate);
    const e = endOfDay(endDate);

    swdHttpGet(`/gaia/locations/${serial}${formatQueryParams({
      start: s.toISOString(),
      end: e.toISOString(),
      limit: pageSize.toString(),
      filterBadPoints: 'true',
    })}`, authToken() || '')
      .then((data) => {
        setLocations(data);
        setLastSubmission(tagLastSubmission());
        if (updateUrl) {
          setSearchParams((params) => {
            params.set('start', s.toISOString());
            params.set('end', e.toISOString());
            params.set('limit', pageSize.toString());
            return params;
          });
        }
      })
      .catch(() => {});
  };

  useEffect(
    () => onSubmit(false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div style={{ height: '80%', flexShrink: 1 }}>
      <AtlasMap locations={locations} useColor fixedSize />

      {/* Header bar with buttons */}
      <div className="flex-column bg-dark rounded-bottom p-3 gap-2 w-100 mb-2">
        <div className="d-flex gap-3 text-light align-items-end">
          <div className="d-grid gap-1">
            <span className="px-2">Start Date</span>
            <ReactDatePicker
              showIcon
              minDate={new Date('2020')}
              maxDate={endDate ?? now}
              placeholderText="Start Date"
              selected={startDate}
              onChange={() => null}
              onSelect={(date) => setStartDate(date)}
              onBlur={(e) => {
                const date = new Date(e.target.value);
                if (datesValid(date, endDate)) setStartDate(date);
              }}
            />
          </div>

          <div className="d-grid gap-1">
            <span className="px-2">End Date</span>
            <ReactDatePicker
              showIcon
              minDate={startDate}
              maxDate={now}
              placeholderText="End Date"
              selected={endDate}
              onChange={() => null}
              onSelect={(date) => setEndDate(date)}
              onBlur={(e) => {
                const date = new Date(e.target.value);
                if (datesValid(startDate, date)) setEndDate(date);
              }}
            />
          </div>

          <div className="d-grid gap-1">
            <span className="px-2">Max Results:</span>
            <select
              className="form-select"
              value={pageSize}
              onChange={(e) => setPageSize(Number.parseInt(e.target.value, 10))}
            >
              <option value={0}>No Limit</option>
              <option value={100}>100</option>
              <option value={1000}>1,000</option>
              <option value={10_000}>10,000</option>
            </select>
          </div>

          <Button
            className="p-2 px-3"
            title="Submit"
            onClick={() => onSubmit(true)}
            disabled={!canSubmit()}
          >
            Submit
          </Button>

          { isDataScientist && locations.length > 0 && (
          <Button
            className="p-2 px-3"
            title="Show/Hide Details"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? 'Hide' : 'Show'}
            {' '}
            Details
            {' '}
            <MdOutlineMap />
          </Button>
          )}

          { isDataScientist && locations.length > 0 && <LocationsCsv locations={locations} filename={`${serial}.csv`} /> }
        </div>
      </div>

      { locations.length > 0 && <LastCheckIn location={locations[0] as FormattedLocation} /> }

      <LocationsTable locations={locations} isOpen={isOpen} />
    </div>
  );
}

export default DeviceMap;
