import type { UserClaim } from '@stonewall-defense/atlas-common';
import { userJwtToken } from './NonReactCookie.Helper';

/**
 * Determines whether the logged-in user's access is restricted.
 * Optionally provide a required claim.
 * If no required claims are provided, do not restrict.
 *
 * Note: accepted roles are typed as WebRole, which excludes 'inspector'
 *
 * @param claim
 * Pass a `UserClaim` to restrict non-matching users
 *
 * @returns `true` if and only if the user should be restricted or not authorized.
 */
const useRestrictAccess = (claim?: UserClaim) => {
  const userToken = userJwtToken();

  if (!userToken || !userToken.user) return true;
  if (!claim) return false;

  const { user } = userToken;

  return !user.claims.includes(claim);
};

export default useRestrictAccess;
