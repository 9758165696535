// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { CurrentTrackingRecord, DeviceCore } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { useUpsertAssociation } from 'apiHooks/Track.Hook';

// ////////////////////////////////////////////////////////////////////////////
// Types
// ////////////////////////////////////////////////////////////////////////////
interface IAssociateTrackerModalParams {
  records: CurrentTrackingRecord[],
  devices: DeviceCore[],
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AssociateTrackerModal({ records, devices }: IAssociateTrackerModalParams) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const [recordId, setRecordId] = React.useState(records[0]?.recordId || 0);
  const [serial, setSerial] = React.useState(devices[0]?.serial || '');

  const upsertAssociation = useUpsertAssociation();

  const toggle = () => setModalOpen(!modalOpen);

  const canSubmit = () => (!!recordId && !!serial);

  const resetState = () => {
    setRecordId(0);
    setSerial('');
  };

  const onSubmit = async () => {
    const params = { recordId, serial };

    const { isConfirmed } = await Swal.fire({
      title: 'Confirm Choice',
      text: 'This will break any existing associations with this device and start tracking this record. Proceed?',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
    });

    if (!isConfirmed) return;

    try {
      await upsertAssociation(params);
    } catch {
      await Swal.fire({
        title: 'Error',
        text: 'Failed to create record',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <Button
        title="Associate Tracker"
        className="btn-secondary"
        onClick={toggle}
      >
        Associate Tracker
      </Button>
      <AtlasModal
        title="Associate an ATLAS to a Tracking Record"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit()}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <span className="px-2">Record to Track:</span>
          <select
            className="form-select"
            value={recordId}
            defaultValue={records[0]?.recordId}
            onChange={(e) => setRecordId(Number.parseInt(e.target.value, 10))}
          >
            {
              records.map(({ recordId: id, recordName }) => (
                <option key={id} value={id}>{recordName}</option>
              ))
            }
          </select>
        </div>
        <div className="form-floating">
          <span className="px-2">Device to Use:</span>
          <select
            className="form-select"
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
          >
            {
              devices.map(({ serial: s }) => (
                <option key={s} value={s}>{s}</option>
              ))
            }
          </select>
        </div>
      </AtlasModal>
    </>
  );
}

export default AssociateTrackerModal;
