// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridTrackLinkCellRenderer(
  { data }: ICellRendererParams<{ recordId: number, recordName: string }>,
) {
  const navigate = useNavigate();

  return (
    <Button color="link" onClick={() => navigate(`/Track/${data?.recordId}`)}>
      {data?.recordName}
    </Button>
  );
}

export default AgGridTrackLinkCellRenderer;
