import {
  useQuery,
  useQueryClient,
  type QueryClient,
} from '@tanstack/react-query';

import { swdHttpGet, swdHttpPatch, swdHttpPost } from '@stonewall-defense/swd-ui-utils';

import { authToken, userJwtToken } from 'utils/Helpers';
import type {
  INewUser,
  IUser,
  IUserUpdate,
} from 'types/user';

const getUserProfilesQuery = {
  queryKey: ['GetUsersProfiles'],
  queryFn: () => swdHttpGet('/auth/v1/profiles', authToken() || '') as Promise<IUser[]>,
};

const useGetUserProfiles = () => useQuery(getUserProfilesQuery);

const useGetSelfProfile = (enabled: boolean = true) => useQuery({
  queryKey: ['GetSelfProfile'],
  queryFn: () => (swdHttpGet('/auth/v1/profiles/self', authToken() || '') as Promise<IUser>),
  enabled,
});

const resetProfileQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ['GetUsersProfiles'] }).catch(() => {});
  queryClient.invalidateQueries({ queryKey: ['GetSelfProfile'] }).catch(() => {});
};

const usePatchUser = () => {
  const queryClient = useQueryClient();

  return (userPatch: IUserUpdate) => (
    swdHttpPatch(
      `/auth/v1/profiles/${userPatch.userId}`,
      authToken() || '',
      userPatch,
    )
  )
    .then(() => resetProfileQueries(queryClient))
    .catch(() => resetProfileQueries(queryClient));
};

const usePatchSelfProfile = () => {
  const queryClient = useQueryClient();

  const userToken = userJwtToken();

  return (userPatch: Partial<IUser>) => (
    swdHttpPatch(`/auth/v1/profiles/${userToken?.user.userId}`, authToken() || '', userPatch)
      .then(() => resetProfileQueries(queryClient))
      .catch(() => resetProfileQueries(queryClient))
  );
};

const postUser = (userPost: INewUser) => swdHttpPost('/auth/v1/profiles/', authToken() || '', userPost);
const usePostUser = () => postUser;

export {
  useGetUserProfiles,
  useGetSelfProfile,

  usePatchUser,
  usePatchSelfProfile,

  usePostUser,
};
