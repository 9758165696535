import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/filter';
import {
  FILTER_TYPES,
} from 'utils/Constants';
import type { LocationField } from './LocationTableColumns';

const LOCATION_INITIAL_FILTERS: ICustomFilter<LocationField> = {
  serial: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  reportTime: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
};

const LocationFilterAtom = atomWithReset<ICustomFilter<LocationField>>(
  LOCATION_INITIAL_FILTERS,
);

const LocationInitialFilterAtom = atomWithReset<ICustomFilter<LocationField>>(
  LOCATION_INITIAL_FILTERS,
);

export {
  LocationFilterAtom,
  LocationInitialFilterAtom,
};
