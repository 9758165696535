import { useAtom } from 'jotai';
import type { atomWithReset } from 'jotai/utils';
import React from 'react';
import type { IColumnFilterText, ICustomFilter } from 'types/filter';
import { DISABLED_TEXT_COLOR_CLASS, ENABLED_TEXT_COLOR_CLASS } from 'utils/Constants';

interface ITextFilterTypeComponent {
  field: string,
  textFilter: IColumnFilterText,
  filterAtom: ReturnType<typeof atomWithReset<ICustomFilter>>,
}

function TextFilter({ textFilter, field, filterAtom }: ITextFilterTypeComponent) {
  const [currentFilter, setCurrentFilter] = useAtom(filterAtom);
  return (
    <label className={`px-1 text-nowrap ${textFilter.isHidden ? DISABLED_TEXT_COLOR_CLASS : ENABLED_TEXT_COLOR_CLASS}`}>
      contains:
      {' '}
      <input
        type="text"
        disabled={textFilter.isHidden}
        value={textFilter.filterValue}
        onChange={(e) => setCurrentFilter({
          ...currentFilter,
          [field]: {
            ...textFilter,
            filterValue: e.target.value,
          },
        })}
      />
    </label>
  );
}

export default TextFilter;
