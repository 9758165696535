import atlasMoveGeo0 from 'assets/img/atlas-move-geo-0.png';
import atlasMoveGeo100 from 'assets/img/atlas-move-geo-100.png';
import atlasMoveGeo125 from 'assets/img/atlas-move-geo-125.png';
import atlasMoveGeo150 from 'assets/img/atlas-move-geo-150.png';
import atlasMoveGeo175 from 'assets/img/atlas-move-geo-175.png';
import atlasMoveGeo200 from 'assets/img/atlas-move-geo-200.png';
import atlasMoveGeo225 from 'assets/img/atlas-move-geo-225.png';
import atlasMoveGeo25 from 'assets/img/atlas-move-geo-25.png';
import atlasMoveGeo250 from 'assets/img/atlas-move-geo-250.png';
import atlasMoveGeo50 from 'assets/img/atlas-move-geo-50.png';
import atlasMoveGeo75 from 'assets/img/atlas-move-geo-75.png';
import atlasMoveGnss0 from 'assets/img/atlas-move-gnss-0.png';
import atlasMoveGnss100 from 'assets/img/atlas-move-gnss-100.png';
import atlasMoveGnss125 from 'assets/img/atlas-move-gnss-125.png';
import atlasMoveGnss150 from 'assets/img/atlas-move-gnss-150.png';
import atlasMoveGnss175 from 'assets/img/atlas-move-gnss-175.png';
import atlasMoveGnss200 from 'assets/img/atlas-move-gnss-200.png';
import atlasMoveGnss225 from 'assets/img/atlas-move-gnss-225.png';
import atlasMoveGnss25 from 'assets/img/atlas-move-gnss-25.png';
import atlasMoveGnss250 from 'assets/img/atlas-move-gnss-250.png';
import atlasMoveGnss50 from 'assets/img/atlas-move-gnss-50.png';
import atlasMoveGnss75 from 'assets/img/atlas-move-gnss-75.png';
import atlasStatGeo0 from 'assets/img/atlas-stat-geo-0.png';
import atlasStatGeo100 from 'assets/img/atlas-stat-geo-100.png';
import atlasStatGeo125 from 'assets/img/atlas-stat-geo-125.png';
import atlasStatGeo150 from 'assets/img/atlas-stat-geo-150.png';
import atlasStatGeo175 from 'assets/img/atlas-stat-geo-175.png';
import atlasStatGeo200 from 'assets/img/atlas-stat-geo-200.png';
import atlasStatGeo225 from 'assets/img/atlas-stat-geo-225.png';
import atlasStatGeo25 from 'assets/img/atlas-stat-geo-25.png';
import atlasStatGeo250 from 'assets/img/atlas-stat-geo-250.png';
import atlasStatGeo50 from 'assets/img/atlas-stat-geo-50.png';
import atlasStatGeo75 from 'assets/img/atlas-stat-geo-75.png';
import atlasStatGnss0 from 'assets/img/atlas-stat-gnss-0.png';
import atlasStatGnss100 from 'assets/img/atlas-stat-gnss-100.png';
import atlasStatGnss125 from 'assets/img/atlas-stat-gnss-125.png';
import atlasStatGnss150 from 'assets/img/atlas-stat-gnss-150.png';
import atlasStatGnss175 from 'assets/img/atlas-stat-gnss-175.png';
import atlasStatGnss200 from 'assets/img/atlas-stat-gnss-200.png';
import atlasStatGnss225 from 'assets/img/atlas-stat-gnss-225.png';
import atlasStatGnss25 from 'assets/img/atlas-stat-gnss-25.png';
import atlasStatGnss250 from 'assets/img/atlas-stat-gnss-250.png';
import atlasStatGnss50 from 'assets/img/atlas-stat-gnss-50.png';
import atlasStatGnss75 from 'assets/img/atlas-stat-gnss-75.png';

const fileNameMap: Record<string, string> = {
  'atlas-move-geo-0': atlasMoveGeo0,
  'atlas-move-geo-100': atlasMoveGeo100,
  'atlas-move-geo-125': atlasMoveGeo125,
  'atlas-move-geo-150': atlasMoveGeo150,
  'atlas-move-geo-175': atlasMoveGeo175,
  'atlas-move-geo-200': atlasMoveGeo200,
  'atlas-move-geo-225': atlasMoveGeo225,
  'atlas-move-geo-25': atlasMoveGeo25,
  'atlas-move-geo-250': atlasMoveGeo250,
  'atlas-move-geo-50': atlasMoveGeo50,
  'atlas-move-geo-75': atlasMoveGeo75,
  'atlas-move-gnss-0': atlasMoveGnss0,
  'atlas-move-gnss-100': atlasMoveGnss100,
  'atlas-move-gnss-125': atlasMoveGnss125,
  'atlas-move-gnss-150': atlasMoveGnss150,
  'atlas-move-gnss-175': atlasMoveGnss175,
  'atlas-move-gnss-200': atlasMoveGnss200,
  'atlas-move-gnss-225': atlasMoveGnss225,
  'atlas-move-gnss-25': atlasMoveGnss25,
  'atlas-move-gnss-250': atlasMoveGnss250,
  'atlas-move-gnss-50': atlasMoveGnss50,
  'atlas-move-gnss-75': atlasMoveGnss75,
  'atlas-stat-geo-0': atlasStatGeo0,
  'atlas-stat-geo-100': atlasStatGeo100,
  'atlas-stat-geo-125': atlasStatGeo125,
  'atlas-stat-geo-150': atlasStatGeo150,
  'atlas-stat-geo-175': atlasStatGeo175,
  'atlas-stat-geo-200': atlasStatGeo200,
  'atlas-stat-geo-225': atlasStatGeo225,
  'atlas-stat-geo-25': atlasStatGeo25,
  'atlas-stat-geo-250': atlasStatGeo250,
  'atlas-stat-geo-50': atlasStatGeo50,
  'atlas-stat-geo-75': atlasStatGeo75,
  'atlas-stat-gnss-0': atlasStatGnss0,
  'atlas-stat-gnss-100': atlasStatGnss100,
  'atlas-stat-gnss-125': atlasStatGnss125,
  'atlas-stat-gnss-150': atlasStatGnss150,
  'atlas-stat-gnss-175': atlasStatGnss175,
  'atlas-stat-gnss-200': atlasStatGnss200,
  'atlas-stat-gnss-225': atlasStatGnss225,
  'atlas-stat-gnss-25': atlasStatGnss25,
  'atlas-stat-gnss-250': atlasStatGnss250,
  'atlas-stat-gnss-50': atlasStatGnss50,
  'atlas-stat-gnss-75': atlasStatGnss75,
};

export default fileNameMap;
