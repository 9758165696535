// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type {
  TrackingHistoryEntry,
  TrackingRecordInstance,
  TrackingRecordWithHistory,
} from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { formatDate } from 'utils/Helpers';
import EditRecordNameModal from './EditRecordName';
import EditRecordDescriptionModal from './EditRecordDescription';
import EditPriorityModal from './EditPriority';

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
function Record({ data }: { data: TrackingRecordInstance }) {
  const {
    recordId, recordName, recordDescription, priority,
  } = data;

  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h3 className="text-secondary px-3">Tracked Record</h3>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Record Name
        </small>
        <EditRecordNameModal recordId={recordId} recordName={recordName} />
      </div>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Record Description
        </small>
        <EditRecordDescriptionModal recordId={recordId} recordDescription={recordDescription} />
      </div>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Priority
        </small>
        <EditPriorityModal recordId={recordId} priority={priority} />
      </div>
    </div>
  );
}

function History({ history }: { history: TrackingHistoryEntry[] }) {
  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h3 className="text-secondary px-3">Tracker History</h3>
      <div className="text-center overflow-auto" style={{ maxHeight: '300px' }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Serial Number</th>
              <th scope="col">Valid Until</th>
            </tr>
          </thead>
          <tbody>
            {history.map((h) => (
              <tr key={h.validUntil}>
                <td>{h.serial}</td>
                <td>{formatDate(h.validUntil)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function TrackingOverview({ data }: { data: TrackingRecordWithHistory }) {
  return (
    <div className="d-flex justify-content-center bg-gray gap-3 pt-2">
      <Record data={data} />
      <History history={data.history} />
    </div>
  );
}

export default TrackingOverview;
