// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { usePatchRecordName } from 'apiHooks/Track.Hook';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface IEditRecordNameModal {
  recordId: number,
  recordName: string,
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function EditRecordNameModal({ recordId, recordName }: IEditRecordNameModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpVal, setTmpVal] = React.useState(recordName);

  const patch = usePatchRecordName(recordId);

  const toggle = () => setModalOpen(!modalOpen);
  const resetState = () => setTmpVal(recordName);

  const onSubmit = () => patch(tmpVal)
    .then(() => {
      toggle();
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{recordName}</span>
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <AtlasModal
        title="Update Record Name"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={recordName !== tmpVal}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="inputRecordName"
            autoComplete="off"
            defaultValue={tmpVal}
            onChange={(event) => setTmpVal(event.target.value)}
            placeholder=" "
          />
          <label htmlFor="inputRecordName">
            Record Name
          </label>
        </div>
      </AtlasModal>
    </>
  );
}

export default EditRecordNameModal;
