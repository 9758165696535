// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { subDays } from 'date-fns';
import { Button } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { ConnectionLog, DeviceLogEntry } from '@stonewall-defense/atlas-common';
import { swdHttpGet } from '@stonewall-defense/swd-ui-utils';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  authToken,
  formatDate,
  formatQueryParams,
  getNumber,
  getTime,
} from 'utils/Helpers';

// ////////////////////////////////////////////////////////////////////////////
// Styles
// ////////////////////////////////////////////////////////////////////////////
import './pagination.css';

// ////////////////////////////////////////////////////////////////////////////
// Config
// ////////////////////////////////////////////////////////////////////////////
const ITEMS_PER_PAGE = 1;

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
function DeviceLogFastFacts({ log }: { log: ConnectionLog }) {
  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h4 className="text-secondary px-3">Fast Facts</h4>
      <div className="text-center overflow-auto" style={{ maxHeight: '400px' }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Field</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Connection Start</th>
              <td>{formatDate(log.connectionStart)}</td>
            </tr>
            <tr>
              <th scope="row">Connection End</th>
              <td>{formatDate(log.connectionEnd)}</td>
            </tr>
            <tr>
              <th scope="row">Disconnect Reason</th>
              <td>{log.endReason}</td>
            </tr>
            <tr>
              <th scope="row">Message Delay</th>
              <td>
                {log.clientMessageDelay}
                {' '}
                sec
              </td>
            </tr>
            <tr>
              <th scope="row">Message Version</th>
              <td>{log.messageVersion}</td>
            </tr>
            <tr>
              <th scope="row">Data Submissions</th>
              <td>{log.numDataSubmissions ?? 'N/A'}</td>
            </tr>
            <tr>
              <th scope="row">A-GNSS Requests</th>
              <td>{log.numAgnssRequests}</td>
            </tr>
            <tr>
              <th scope="row">Geolocation Requests</th>
              <td>{log.numGeoRequests}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function DeviceLogErrors({ errors }: { errors: string[] }) {
  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h4 className="text-secondary px-3">
        Reported Errors (
        {errors.length}
        )
      </h4>
      <div className="text-center overflow-auto" style={{ maxHeight: '400px' }}>
        <table className="table table-striped">
          <tbody>
            {
              errors.map((e, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={idx}>
                  <td>{e}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

function DeviceLogInfoMessages({ messages }: { messages: string[] }) {
  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h4 className="text-secondary px-3">
        Info Messages (
        {messages.length}
        )
      </h4>
      <div className="text-center overflow-auto" style={{ maxHeight: '400px' }}>
        <table className="table table-striped">
          <tbody>
            {
              messages.map((m, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={idx}>
                  <td>{m}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

function DeviceLogPage({ log }: { log: DeviceLogEntry }) {
  return (
    <>
      <h3 className="text-center">
        Connection #
        {' '}
        {log.recordId}
        {' '}
        for device
        {' '}
        {log.serial}
        {' '}
        at
        {' '}
        {formatDate(log.creationTime)}
      </h3>
      <div className="d-flex justify-content-center bg-gray gap-3 pt-2">
        <DeviceLogFastFacts log={log.connectionLog} />
        <DeviceLogErrors errors={log.connectionLog.errors} />
        <DeviceLogInfoMessages messages={log.connectionLog.info} />
      </div>
    </>
  );
}

function DeviceLogTable({ serial }: { serial: string }) {
  const now = new Date();

  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date>(getTime(searchParams.get('start'), subDays(now, 7)));
  const [endDate, setEndDate] = useState<Date>(getTime(searchParams.get('end'), now));
  const [pageSize, setPageSize] = useState<number>(getNumber(searchParams.get('limit'), 10));

  const [logs, setLogs] = useState<DeviceLogEntry[]>([]);
  const [logOffset, setLogOffset] = useState(0);

  const navigate = useNavigate();

  const datesValid = (start: Date, end: Date) => (start && end && start <= end);

  const onSubmit = (updateUrl: boolean) => {
    swdHttpGet(`/gaia/devices/${serial}/logs${formatQueryParams({
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      limit: pageSize.toString(),
      filterBadPoints: 'true',
    })}`, authToken() || '')
      .then((data) => {
        setLogs(data);
        if (updateUrl) {
          setSearchParams((params) => {
            params.set('start', startDate.toISOString());
            params.set('end', endDate.toISOString());
            params.set('limit', pageSize.toString());
            return params;
          });
        }
      })
      .catch(() => {});
  };

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % logs.length;
    setLogOffset(newOffset);
  };

  const handleClickBack = () => navigate(`/Device/${serial}`);

  useEffect(
    () => onSubmit(false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {/* Header bar with buttons */}
      <div className="flex-column bg-dark rounded-top p-3 gap-2 w-100">
        <div className="d-flex gap-3 text-light align-items-end">

          {/* Start Date */}
          <div className="d-grid gap-1">
            <span className="px-2">Start Date</span>
            <ReactDatePicker
              showIcon
              minDate={new Date('2020')}
              maxDate={endDate ?? now}
              placeholderText="Start Date"
              selected={startDate}
              onChange={() => null}
              onSelect={(date) => setStartDate(date)}
              onBlur={(e) => {
                const date = new Date(e.target.value);
                if (datesValid(date, endDate)) setStartDate(date);
              }}
            />
          </div>

          {/* End Date */}
          <div className="d-grid gap-1">
            <span className="px-2">End Date</span>
            <ReactDatePicker
              showIcon
              minDate={startDate}
              maxDate={now}
              placeholderText="End Date"
              selected={endDate}
              onChange={() => null}
              onSelect={(date) => setEndDate(date)}
              onBlur={(e) => {
                const date = new Date(e.target.value);
                if (datesValid(startDate, date)) setEndDate(date);
              }}
            />
          </div>

          {/* Limit */}
          <div className="d-grid gap-1">
            <span className="px-2">Max Results:</span>
            <select
              className="form-select"
              value={pageSize}
              onChange={(e) => setPageSize(Number.parseInt(e.target.value, 10))}
            >
              <option value={0}>No Limit</option>
              <option value={10}>10</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
            </select>
          </div>

          <Button
            className="p-2 px-3"
            title="Submit"
            onClick={() => onSubmit(true)}
          >
            Submit
          </Button>

          <Button
            className="p-2 px-3"
            title="Back"
            onClick={handleClickBack}
          >
            Back
          </Button>
        </div>
      </div>
      { logs.length > 0
        ? (
          <div>
            <DeviceLogPage log={logs[logOffset] as DeviceLogEntry} />
            <div className="pagination-div">
              <hr />
              <ReactPaginate
                activeClassName="page-item active "
                breakClassName="page-item "
                breakLabel="..."
                containerClassName="pagination "
                disabledClassName="disabled-page "
                nextClassName="page-item next "
                nextLabel={<MdNavigateNext style={{ fontSize: 24, width: 150 }} />}
                onPageChange={handlePageClick}
                pageCount={logs.length}
                pageClassName="page-item pagination-page "
                previousLabel={<MdNavigateBefore style={{ fontSize: 24, width: 150 }} />}
                previousClassName="page-item previous "
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        ) : <p>No data to show for that time period</p> }
    </>
  );
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
export default function DeviceLogs() {
  const { serial } = useParams();
  const navigate = useNavigate();

  if (!serial) {
    navigate('/');
    return <div />;
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100 w-100">
      <h2 className="text-secondary px-3">
        Connection Logs for
        {' '}
        {serial}
      </h2>
      <DeviceLogTable serial={serial} />
    </div>
  );
}
