import React from 'react';

import type { TrackingPriority } from '@stonewall-defense/atlas-common';

export default function Priority({ priority }: { priority: TrackingPriority }) {
  switch (priority) {
    case 'low': { return <span className="text-success">Low</span>; }
    case 'normal': { return <span>Normal</span>; }
    case 'high': { return <span className="text-warning">High</span>; }
    case 'critical': { return <span className="text-danger">Critical</span>; }
    default: { return <span>N/A</span>; }
  }
}
