// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { CurrentTrackingRecord, FormattedLocation } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import AtlasMap from 'components/leafletMapUtils/AtlasMap';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface ITrackingMapParams {
  locations: FormattedLocation[] | undefined,
  trackingData: CurrentTrackingRecord[] | undefined,
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function TrackingMap({ locations, trackingData }: ITrackingMapParams) {
  let filteredLocations: FormattedLocation[] = [];

  // TODO: See if there is a cleaner way to handle this
  if (locations && trackingData) {
    const recordMap = trackingData
      .filter((r) => !!r.serial)
      .reduce<Record<string, CurrentTrackingRecord>>(
      (agg, curr) => ({
        ...agg,
        [(curr.serial as string)]: curr,
      }),
      {},
    );

    filteredLocations = locations
      .filter((l) => !!recordMap[l.serial])
      .map((l) => ({ ...l, serial: recordMap[l.serial]?.recordName ?? l.serial }));
  }

  return <AtlasMap locations={filteredLocations} padBottom showEmptyMap />;
}

export default TrackingMap;
