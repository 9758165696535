// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useState } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardImgOverlay,
  CardImg,
  Dropdown,
} from 'reactstrap';
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from 'react-pro-sidebar';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { Twirl as Hamburger } from 'hamburger-react';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { JwtToken } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import useLogout from 'hooks/UseLogout';
import { userJwtToken } from 'utils/Helpers';
import viewRoutes from 'views/ViewRoutes';
import EditProfileModal from 'views/UserProfile/EditProfileModal';
import { FaUser } from 'react-icons/fa';
import logo from 'assets/img/atlas.jpeg';

// ////////////////////////////////////////////////////////////////////////////
// Styles
// ////////////////////////////////////////////////////////////////////////////
import styles from './Sidebar.module.css';

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
const formatNameFromToken = (userToken : JwtToken | undefined) => {
  if (!userToken) {
    return '';
  }

  const { names } = userToken.user;

  return [
    names.givenName || '',
    names.familyName || '',
  ].filter(Boolean).join(' ').trim();
};

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function SideBar() {
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const toggleUserDropdownOpen = () => setUserDropdownOpen((b) => !b);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const userToken = userJwtToken();
  const logout = useLogout();
  const navigate = useNavigate();
  const {
    collapseSidebar,
    toggleSidebar,
    broken,
    collapsed,
  } = useProSidebar();

  return (
    <Sidebar
      backgroundColor="transparent"
      className={`min-vh-100 bg-secondary bg-gradient pr-4 text-white border-0 ${styles['sidebar'] || ''}`}
      breakPoint="lg"
      onClick={() => (userDropdownOpen ? setUserDropdownOpen(false) : undefined)}
    >

      <div className={(collapsed ? 'py-2' : 'px-3')}>
        <CardImg
          alt="ATLAS"
          src={logo}
          className="radial-metal-logo"
        />
      </div>
      <CardImgOverlay>
        <div className="d-flex justify-content-end">
          <Hamburger
            toggled={!collapsed}
            color="var(--bs-primary)"
            onToggle={() => (broken ? toggleSidebar() : collapseSidebar())}
          />
        </div>
      </CardImgOverlay>
      <div className="text-center">
        <h3>ATLAS</h3>
        <span className="text-center text-white col-lg">{ userToken?.org.orgName }</span>
      </div>
      <Menu className="mb-auto">
        <Dropdown
          isOpen={userDropdownOpen}
          toggle={toggleUserDropdownOpen}
          direction="end"
          className="mb-5 rounded-0"
        >
          <DropdownToggle nav>
            <MenuItem
              component="div"
              className={styles['menu-item']}
              key="/Profile"
              icon={<FaUser />}
            >
              <span>{formatNameFromToken(userToken)}</span>
            </MenuItem>
          </DropdownToggle>

          <DropdownMenu
            end
            data-bs-popper="static"
            container="body"
            className="rounded-0 rounded-end shadow-lg p-0"
          >

            <EditProfileModal
              modalOpen={editProfileModalOpen}
              setModalOpen={setEditProfileModalOpen}
              onClick={toggleUserDropdownOpen}
            />

            <DropdownItem
              onClick={() => {
                toggleUserDropdownOpen();
                navigate('/Admin');
              }}
              className="rounded"
            >
              Users
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem
              onClick={() => logout()}
              className="rounded"
            >
              Log out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {
          viewRoutes.filter((route) => !route.hideSidebarLink)
            .map((route) => (
              <MenuItem
                className={styles['menu-item']}
                key={route.path}
                icon={route.icon}
                component={<Link to={route.path} />}
              >
                {route.label}
              </MenuItem>
            ))
        }
      </Menu>
    </Sidebar>
  );
}

export default SideBar;
