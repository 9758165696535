import { useAtom } from 'jotai';
import React from 'react';
import { FaCheck, FaTimes, FaExclamationTriangle } from 'react-icons/fa';
import type { IColumnFilterSet, ICustomFilter } from 'types/filter';
import { DISABLED_TEXT_COLOR_CLASS, ENABLED_TEXT_COLOR_CLASS } from 'utils/Constants';
import { Button } from 'reactstrap';
import type { atomWithReset } from 'jotai/utils';
import FilterButton from './FilterButton';

interface ISetFilterTypeComponent {
  field: string,
  setFilter: IColumnFilterSet,
  filterAtom: ReturnType<typeof atomWithReset<ICustomFilter>>,
}

function SetFilter({ setFilter, field, filterAtom }: ISetFilterTypeComponent) {
  const [currentFilter, setCurrentFilter] = useAtom(filterAtom);
  if (setFilter.filterValues.length === 0) {
    return <div />;
  }

  const selectAll = () => {
    setCurrentFilter({
      ...currentFilter,
      [field]: {
        ...setFilter,
        filterValues: [...setFilter.filterValues].fill(true),
      },
    });
  };

  const selectNone = () => {
    setCurrentFilter({
      ...currentFilter,
      [field]: {
        ...setFilter,
        filterValues: [...setFilter.filterValues].fill(false),
      },
    });
  };

  const setFilterChange = (isFiltered: boolean, idx: number) => {
    const newFilter = [...setFilter.filterValues];

    newFilter.splice(idx, 1, isFiltered);
    setCurrentFilter({
      ...currentFilter,
      [field]: {
        ...setFilter,
        filterValues: newFilter,
      },
    });
  };

  if (field === 'status') {
    return (
      <div className="d-flex gap-1 container py-1 border-bottom">
        <FilterButton
          active={!!setFilter.filterValues[0]}
          disabled={setFilter.isHidden}
          onClick={() => setFilterChange(!setFilter.filterValues[0], 0)}
        >
          <FaCheck className="text-success" />
        </FilterButton>
        <FilterButton
          active={!!setFilter.filterValues[1]}
          disabled={setFilter.isHidden}
          onClick={() => setFilterChange(!setFilter.filterValues[1], 1)}
        >
          <FaTimes className="text-danger" />
        </FilterButton>
        <FilterButton
          active={!!setFilter.filterValues[2]}
          disabled={setFilter.isHidden}
          onClick={() => setFilterChange(!setFilter.filterValues[2], 2)}
        >
          <FaExclamationTriangle className="text-warning" />
        </FilterButton>

      </div>
    );
  }

  return (
    <div className="d-flex border-bottom py-1">
      <div className="d-flex flex-wrap container gap-1">
        {
          setFilter.filterValues.map((isChecked, idx) => (
            <div key={`columnOf-${setFilter.labels[idx]}`}>
              <label className={`d-flex${setFilter.isHidden ? DISABLED_TEXT_COLOR_CLASS : ENABLED_TEXT_COLOR_CLASS}`}>
                <FilterButton
                  active={isChecked}
                  disabled={setFilter.isHidden}
                  onClick={() => setFilterChange(!isChecked, idx)}
                >
                  {setFilter.labels[idx]}
                </FilterButton>
              </label>
            </div>
          ))
        }
      </div>
      <div className="d-flex flex-column justify-content-center text-nowrap gap-1">
        <Button color="primary" className="px-1 py-0" onClick={selectAll}>
          Select All
        </Button>
        <Button color="primary" className=" px-1 py-0" onClick={selectNone}>
          Deselect All
        </Button>
      </div>
    </div>
  );
}

export default SetFilter;
