// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { useGetDevicesAndLocations } from 'apiHooks/Collection.Hook';
import Loading from 'components/shared/Loading/Loading';
import AtlasMap from 'components/leafletMapUtils/AtlasMap';
import DashboardTable from './DashboardTable';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function Dashboard() {
  const [
    {
      data: locationsData,
      isLoading: locationsLoading,
      isError: islocationsError,
      error: locationsError,
    },
    {
      data: devicesData,
      isLoading: devicesLoading,
      isError: isdevicesError,
      error: devicesError,
    },
  ] = useGetDevicesAndLocations();

  const isLoading = locationsLoading || devicesLoading;
  const isError = islocationsError || isdevicesError;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <>
        <div>{locationsError as string}</div>
        <div>{devicesError as string}</div>
      </>
    );
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100 w-100">
      <h2 className="text-secondary px-3">Device Overview</h2>
      <AtlasMap locations={locationsData || []} padBottom />
      <DashboardTable locations={locationsData} devices={devicesData} />
    </div>
  );
}

export default Dashboard;
