import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/filter';
import {
  FILTER_TYPES,
} from 'utils/Constants';
import type { DeviceField } from './DeviceTableColumns';

const DEVCICE_INITIAL_FILTERS: ICustomFilter<DeviceField> = {
  serial: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  reportTime: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  location: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
};

const DeviceFilterAtom = atomWithReset<ICustomFilter<DeviceField>>(
  DEVCICE_INITIAL_FILTERS,
);

const DeviceInitialFilterAtom = atomWithReset<ICustomFilter<DeviceField>>(
  DEVCICE_INITIAL_FILTERS,
);

export {
  DeviceFilterAtom,
  DeviceInitialFilterAtom,
};
