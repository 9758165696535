import { useCookies } from 'react-cookie';
import { useQueryClient } from '@tanstack/react-query';

import { COOKIE_NAME } from 'utils/Constants';

const useLogout = () => {
  const [,,removeCookie] = useCookies();
  const queryClient = useQueryClient();

  return () => {
    removeCookie(COOKIE_NAME, { path: '/' });
    queryClient.clear();
  };
};

export default useLogout;
