// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { useGetTrackingAndLocations } from 'apiHooks/Track.Hook';
import Loading from 'components/shared/Loading/Loading';
import TrackingMap from './TrackingMap';
import TrackingTable from './TrackingTable';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function TrackingDash() {
  const [
    {
      data: locationsData,
      isLoading: locationsLoading,
      isError: isLocationsError,
      error: locationsError,
    },
    {
      data: deviceData,
      isLoading: deviceLoading,
      isError: isDeviceError,
      error: deviceError,
    },
    {
      data: trackingData,
      isLoading: trackingLoading,
      isError: isTrackingError,
      error: trackingError,
    },
  ] = useGetTrackingAndLocations();

  const isLoading = locationsLoading || deviceLoading || trackingLoading;
  const isError = isLocationsError || isDeviceError || isTrackingError;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <>
        <div>{locationsError as string}</div>
        <div>{deviceError as string}</div>
        <div>{trackingError as string}</div>
      </>
    );
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100 w-100">
      <h2 className="text-secondary px-3">Tracking Overview</h2>
      <TrackingMap
        locations={locationsData}
        trackingData={trackingData}
      />
      <TrackingTable
        locations={locationsData}
        trackingData={trackingData}
        deviceData={deviceData}
      />
    </div>
  );
}

export default TrackingDash;
