import type { ColDef } from '@ag-grid-community/core';
import { atomWithReset } from 'jotai/utils';

import AgGridBooleanIconCellRenderer from 'components/agGridRenderers/AgGridBooleanIconCellRenderer';
import AgGridUserAdminEditCellRenderer from 'views/UserAdmin/AgGridUserAdminEditCellRenderer';
import {
  AG_GRID_USER_ADMIN_FIELDS,
  AG_GRID_USER_ADMIN_HEADER_MAP,
} from './UserAdminTableColumns';

const initialColumns: ColDef[] = (
  AG_GRID_USER_ADMIN_FIELDS.map((field) => {
    const headerName = AG_GRID_USER_ADMIN_HEADER_MAP[field] || '';

    if (field === 'active') {
      return {
        field,
        headerName,
        cellRenderer: AgGridBooleanIconCellRenderer,
        width: 100,
        initialHide: true,
      };
    }

    if (field === 'claims') {
      return {
        field,
        headerName,
        valueGetter: (params) => (params.data?.claims?.join(', ')),
        width: 150,
      };
    }

    if (field === 'actions') {
      return {
        field,
        headerName,
        cellRenderer: AgGridUserAdminEditCellRenderer,
        width: 100,
      };
    }

    return {
      field,
      headerName,
    };
  })
);

const UserAdminAgGridColDefsAtom = atomWithReset<ColDef[]>(initialColumns);

export default UserAdminAgGridColDefsAtom;
