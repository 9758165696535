// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import type { ICellRendererParams } from '@ag-grid-community/core';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridYesNoCellRenderer(
  { data }: ICellRendererParams<{ moving: boolean }>,
) {
  // eslint-disable-next-line unicorn/no-negated-condition
  return data?.moving !== null ? (
    <div>
      {data?.moving ? 'Yes' : 'No'}
    </div>
  ) : null;
}

export default AgGridYesNoCellRenderer;
