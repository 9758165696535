// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { Cookies } from 'react-cookie';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import { decodeJwt } from '@stonewall-defense/swd-ui-utils';
import type { JwtToken } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import COOKIE_NAME from 'utils/constants/CookieName';

// ////////////////////////////////////////////////////////////////////////////
// Config
// ////////////////////////////////////////////////////////////////////////////
const cookies = new Cookies();

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
const authToken = () => (cookies.get(COOKIE_NAME) as string | undefined);

const userJwtToken = () => {
  const appCookie = authToken();

  if (!appCookie || !decodeJwt(appCookie)) {
    return undefined;
  }
  return decodeJwt(appCookie) as JwtToken;
};

const userOrganizationId = () => {
  const jwtToken = userJwtToken();
  return jwtToken?.org.orgId || '';
};

const userOrganizationName = () => {
  const jwtToken = userJwtToken();
  return jwtToken?.org.orgName || '';
};

export {
  authToken,
  userJwtToken,
  userOrganizationId,
  userOrganizationName,
};
