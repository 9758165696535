// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { TrackingPriority } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import Priority from 'components/shared/Priority';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridPriorityCellRenderer(
  { data }: ICellRendererParams<{ priority: TrackingPriority }>,
) {
  return data?.priority ? (
    <Priority priority={data?.priority} />
  ) : null;
}

export default AgGridPriorityCellRenderer;
