import React from 'react';
import {
  Button,
} from 'reactstrap';
import { MdDetails } from 'react-icons/md';

import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { useIccid } from 'apiHooks/Details.Hook';
import Loading from 'components/shared/Loading/Loading';
import { BinaryIcon } from 'utils/Helpers';

function IccidModal({ iccid, isTech }: { iccid: string, isTech: boolean }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const {
    data,
    isLoading,
    isError,
    error,
  } = useIccid(iccid);

  const toggle = () => setModalOpen(!modalOpen);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>{error as string}</div>;
  }

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{iccid}</span>
        { isTech && (
        <Button color="link" onClick={toggle} className="py-0">
          <MdDetails />
        </Button>
        )}
      </div>
      <AtlasModal
        title="ICCID Details"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
      >
        <div className="form-floating">
          <p>
            Value:
            {' '}
            {data.value}
          </p>
          <p>
            Valid:
            {' '}
            <BinaryIcon isGood={data.isValid} />
          </p>
          <p>
            Carrier:
            {' '}
            {data.carrierUrl ? <a href={data.carrierUrl}>{data.carrier}</a> : 'N/A'}
          </p>
        </div>
      </AtlasModal>
    </>
  );
}

export default IccidModal;
