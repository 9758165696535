// /////////////////////////////////////////////////////////////////////////////
// React Imports
// /////////////////////////////////////////////////////////////////////////////
import React from 'react';

// /////////////////////////////////////////////////////////////////////////////
// SWD Imports
// /////////////////////////////////////////////////////////////////////////////
import type { FormattedLocation } from '@stonewall-defense/atlas-common';

// /////////////////////////////////////////////////////////////////////////////
// Local Imports
// /////////////////////////////////////////////////////////////////////////////
import { formatDate } from 'utils/Helpers';

// /////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// /////////////////////////////////////////////////////////////////////////////
interface ILocationTableProps {
  locations: FormattedLocation[],
  isOpen: boolean,
}

// /////////////////////////////////////////////////////////////////////////////
// Exports
// /////////////////////////////////////////////////////////////////////////////
export default function LocationsTable({ locations, isOpen }: ILocationTableProps) {
  return (
    <div className={`text-center overflow-auto ${isOpen ? '' : 'd-none'}`} style={{ maxHeight: '400px' }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Location ID</th>
            <th scope="col">Device Serial</th>
            <th scope="col">Report Time</th>
            <th scope="col">Latitude</th>
            <th scope="col">Longitude</th>
            <th scope="col">Altitude (m HAE)</th>
            <th scope="col">Uncertainty (m)</th>
            <th scope="col">Method</th>
            <th scope="col">Battery (%)</th>
            <th scope="col">Moving</th>
            <th scope="col">Agnss</th>
            <th scope="col">Geolocation</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((l) => (
            <tr key={l.locationId}>
              <th scope="row">{l.locationId}</th>
              <td>{l.serial}</td>
              <td>{formatDate(l.reportTime)}</td>
              <td>{l.location.latitude}</td>
              <td>{l.location.longitude}</td>
              <td>{l.altitude}</td>
              <td>{l.uncertainty}</td>
              <td>{l.posMethod}</td>
              <td>{l.battery}</td>
              <td>{l.moving ? 'Yes' : 'No'}</td>
              <td>{l.usedAgnss ? 'Yes' : ''}</td>
              <td>{l.usedGeolocation ? 'Yes' : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
