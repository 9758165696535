import type { ICustomFilter } from 'types/filter';
import { FILTER_TYPES } from 'utils/Constants';

const AG_GRID_TRACKING_FIELDS = [
  'recordName',
  'recordDescription',
  'priority',
  'serial',
  'reportTime',
  'location',
  'posMethod',
  'battery',
  'moving',
] as const;

type TrackingField = typeof AG_GRID_TRACKING_FIELDS[number];

const AG_GRID_TRACKING_HEADER_MAP: Record<TrackingField, string> = {
  recordName: 'Name',
  recordDescription: 'Description',
  priority: 'Priority',
  serial: 'Device',
  reportTime: 'Last Check-in',
  location: 'Location',
  posMethod: 'Accuracy',
  battery: 'Battery',
  moving: 'Moving',
};

const TRACKING_FIELD_INITIAL_FILTERS: ICustomFilter<TrackingField> = {
  recordName: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  recordDescription: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  priority: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  serial: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  reportTime: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: false,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  posMethod: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  battery: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  moving: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
};

export {
  AG_GRID_TRACKING_FIELDS,
  type TrackingField,
  AG_GRID_TRACKING_HEADER_MAP,
  TRACKING_FIELD_INITIAL_FILTERS,
};
