/* eslint-disable react/jsx-props-no-spreading */
import type { SetStateAction } from 'react';
import React from 'react';
import type {
  ButtonProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
} from 'reactstrap';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import SubmitButton from '../SubmitButton';

interface AtlasModalContainerProps extends ModalProps {}

function AtlasModalContainer(props: AtlasModalContainerProps) {
  const { children } = props;
  return (
    <Modal {...props} size="lg">
      {children}
    </Modal>
  );
}

interface AtlasModalHeaderProps extends ModalHeaderProps {
  toggle: () => void,
}

function AtlasModalHeader(props: AtlasModalHeaderProps) {
  const { children, className } = props;
  return (
    <ModalHeader
      {...props}
      className={`bg-secondary text-light user-select-none ${className}`}
    >
      {children}
    </ModalHeader>
  );
}

interface AtlasModalBodyProps extends ModalBodyProps {}

function AtlasModalBody(props: AtlasModalBodyProps) {
  return <ModalBody {...props} />;
}

export interface AdditionalButtonProps extends ButtonProps {
  title: string;
}
interface AtlasModalFooterProps extends ModalFooterProps {
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  canSubmit?: boolean | undefined;
  onSubmit?: (() => Promise<void | void[]>) | undefined;
  submitButtonText?: string | undefined;
  additionalButtons?: AdditionalButtonProps[] | undefined;
}

function AtlasModalFooter(props: AtlasModalFooterProps) {
  const {
    setIsOpen,
    canSubmit,
    onSubmit,
    submitButtonText,
    additionalButtons,
    children,
  } = props;

  const handleSubmit = onSubmit ? (
    () => (
      onSubmit()
        .then(() => setIsOpen(false))
        .catch(() => {})
    )
  ) : (
    undefined
  );

  return (
    <ModalFooter className="justify-content-between">
      {children || (
        <>
          <Button
            title="Cancel"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          {
            additionalButtons?.map((buttonProps) => (
              <Button key={buttonProps.title} {...buttonProps}>
                {buttonProps.title}
              </Button>
            ))
          }
          {handleSubmit && (
            <SubmitButton
              canSubmit={canSubmit ?? true}
              onSubmit={handleSubmit}
            >
              {submitButtonText}
            </SubmitButton>
          )}
        </>
      )}
    </ModalFooter>
  );
}

interface AtlasModalProps extends AtlasModalContainerProps {
  title: string | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  canSubmit?: boolean;
  onSubmit?: (() => Promise<void | void[]>) | undefined;
  headerClassName?: string | undefined;
  bodyClassName?: string | undefined;
  footerClassName?: string | undefined;
  submitButtonText?: string | undefined;
  additionalButtons?: AdditionalButtonProps[];
}

/**
 * All-in-one Modal component
 */
function AtlasModal(props: AtlasModalProps) {
  const {
    title,
    canSubmit,
    onSubmit,
    children,
    headerClassName,
    bodyClassName,
    footerClassName,
    setIsOpen,
    submitButtonText,
    additionalButtons,
  } = props;

  const toggle = () => setIsOpen((state) => !state);

  return (
    <AtlasModalContainer {...props} toggle={toggle}>
      <AtlasModalHeader toggle={toggle} className={headerClassName}>
        {title}
      </AtlasModalHeader>

      <AtlasModalBody className={bodyClassName}>
        {children}
      </AtlasModalBody>

      <AtlasModalFooter
        className={footerClassName}
        setIsOpen={setIsOpen}
        canSubmit={canSubmit}
        onSubmit={onSubmit}
        submitButtonText={submitButtonText}
        additionalButtons={additionalButtons}
      />

    </AtlasModalContainer>
  );
}

/* eslint-enable react/jsx-props-no-spreading */

export default AtlasModal;

export {
  AtlasModalContainer,
  AtlasModalHeader,
  AtlasModalBody,
};
