// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { ILatLon } from '@stonewall-defense/coord-config';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridLocationCellRenderer(
  { data }: ICellRendererParams<{ location: ILatLon }>,
) {
  return data?.location ? (
    <div>
      {data.location.latitude}
      {', '}
      {data.location.longitude}
    </div>
  ) : null;
}

export default AgGridLocationCellRenderer;
