// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { TrackingPriority } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import type { Nullable } from 'types/common';
import { usePostTrackingRecord } from 'apiHooks/Track.Hook';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AddRecordModal() {
  const [modalOpen, setModalOpen] = React.useState(false);

  const [recordName, setRecordName] = React.useState('');
  const [recordDescription, setRecordDescription] = React.useState<Nullable<string>>(null);
  const [priority, setPriority] = React.useState<TrackingPriority>('normal');

  const postTrackingRecord = usePostTrackingRecord();

  const toggle = () => setModalOpen(!modalOpen);

  const canSubmit = () => (!!recordName && !!priority);
  const resetState = () => {
    setRecordName('');
    setRecordDescription(null);
    setPriority('normal');
  };

  const onSubmit = async () => {
    const params = { recordName, recordDescription, priority };

    try {
      await postTrackingRecord(params);
    } catch {
      await Swal.fire({
        title: 'Error',
        text: 'Failed to create record',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <Button
        title="Create Record"
        className="btn-secondary"
        onClick={toggle}
      >
        New Record
      </Button>
      <AtlasModal
        title="Create a New Tracking Record"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit()}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="input-record-name"
            autoComplete="off"
            defaultValue={recordName}
            onChange={(event) => setRecordName(event.target.value)}
            required
            placeholder=" "
          />
          <label htmlFor="input-record-name">
            Record Name
          </label>
        </div>
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="input-record-description"
            autoComplete="off"
            defaultValue={recordDescription || ''}
            onChange={(event) => setRecordDescription(event.target.value)}
            required
            placeholder=" "
          />
          <label htmlFor="input-record-description">
            Record Description
          </label>
        </div>
        <div className="form-floating">
          <span className="px-2">Priority:</span>
          <select
            className="form-select"
            value={priority}
            defaultValue="normal"
            onChange={(e) => setPriority(e.target.value as TrackingPriority)}
          >
            <option value="low">Low</option>
            <option value="normal">Normal</option>
            <option value="high">High</option>
            <option value="critical">Critical</option>
          </select>
        </div>
      </AtlasModal>
    </>
  );
}

export default AddRecordModal;
