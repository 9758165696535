import type { ICustomFilter } from 'types/filter';
import { FILTER_TYPES } from 'utils/Constants';

const AG_GRID_LOCATION_FIELDS = [
  'locationId',
  'serial',
  'reportTime',
  'location',
  'posMethod',
  'uncertainty',
  'altitude',
  'battery',
  'moving',
  'usedAgnss',
  'usedGeolocation',
] as const;

type LocationField = typeof AG_GRID_LOCATION_FIELDS[number];

const AG_GRID_LOCATION_HEADER_MAP: Record<LocationField, string> = {
  locationId: 'Location ID',
  serial: 'Serial Number',
  reportTime: 'Last Check-in',
  location: 'Location',
  posMethod: 'Method',
  uncertainty: 'Uncertainty',
  altitude: 'Altitude',
  battery: 'Battery',
  moving: 'Moving',
  usedAgnss: 'AGNSS',
  usedGeolocation: 'Geolocation',
};

const LOCATION_FIELD_INITIAL_FILTERS: ICustomFilter<LocationField> = {
  locationId: {
    filterValue: null,
    rangeType: '=',
    isHidden: false,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  serial: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  reportTime: {
    filterValue: {
      startDateTime: null,
      endDateTime: null,
    },
    isHidden: false,
    filterType: FILTER_TYPES.DATE_TIME,
  },
  posMethod: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  uncertainty: {
    filterValue: null,
    rangeType: '=',
    isHidden: false,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  altitude: {
    filterValue: null,
    rangeType: '=',
    isHidden: false,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  battery: {
    filterValue: null,
    rangeType: '=',
    isHidden: false,
    filterType: FILTER_TYPES.NUMBER_RANGE,
  },
  moving: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
  usedAgnss: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
  usedGeolocation: {
    filterType: FILTER_TYPES.BOOLEAN,
    options: [true, false],
    labels: ['Yes', 'No'],
    filterValues: [true, false],
    isHidden: false,
  },
};

export {
  AG_GRID_LOCATION_FIELDS,
  type LocationField,
  AG_GRID_LOCATION_HEADER_MAP,
  LOCATION_FIELD_INITIAL_FILTERS,
};
