// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridDeviceLinkCellRenderer(
  { data }: ICellRendererParams<{ serial: string }>,
) {
  const navigate = useNavigate();

  return (
    <Button color="link" onClick={() => navigate(`/Device/${data?.serial}`)}>
      {data?.serial}
    </Button>
  );
}

export default AgGridDeviceLinkCellRenderer;
