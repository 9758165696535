import React from 'react';
import { Spinner } from 'reactstrap';

function Loading() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center gap-2 h-100 w-100">
      <Spinner className="text-muted" />
      <div className="text-muted" id="loading-message">
        Loading...
      </div>
    </div>
  );
}

export default Loading;
