import React, { useState } from 'react';
import type { ButtonProps } from 'reactstrap';
import { Button, Spinner } from 'reactstrap';

interface SubmitButtonProps extends Omit<ButtonProps, 'onClick' | 'onSubmit'> {
  canSubmit: boolean;
  onSubmit: () => Promise<void | void[]>;
}

/* eslint-disable react/jsx-props-no-spreading */

export default function SubmitButton(props: SubmitButtonProps) {
  const {
    onSubmit,
    canSubmit,
    title,
    children,
    htmlProps,
  } = props;
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!canSubmit) return;
    setSubmitting(true);
    await onSubmit()
      .finally(() => setSubmitting(false));
  };

  return (
    <Button
      title={title ?? 'Submit'}
      {...htmlProps}
      onClick={handleSubmit}
      disabled={!canSubmit || submitting}
    >
      {submitting ? (
        <Spinner title="submit-button-spinner" size="sm" />
      ) : (
        children || 'Submit'
      )}
    </Button>
  );
}

/* eslint-enable react/jsx-props-no-spreading */
