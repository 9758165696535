// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { useNavigate, useParams } from 'react-router-dom';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { useRecordInstance } from 'apiHooks/Track.Hook';
import Loading from 'components/shared/Loading/Loading';
import TrackingMap from './TrackingMap';
import TrackingOverview from './TrackingOverview';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function TrackingDetails() {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    isError,
    error,
  } = useRecordInstance(recordId ? Number.parseInt(recordId, 10) : 0);

  if (!recordId) {
    navigate('/');
    return <div />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>{ error as string }</div>;
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100 w-100">
      <h2 className="text-secondary px-3">
        Traking Details for
        {' '}
        {data.recordName}
      </h2>
      <TrackingMap history={data.history} />
      <TrackingOverview data={data} />
    </div>
  );
}

export default TrackingDetails;
