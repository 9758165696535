// /////////////////////////////////////////////////////////////////////////////
// React Imports
// /////////////////////////////////////////////////////////////////////////////
import React from 'react';

// /////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// /////////////////////////////////////////////////////////////////////////////
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { MdEdit } from 'react-icons/md';

// /////////////////////////////////////////////////////////////////////////////
// SWD Imports
// /////////////////////////////////////////////////////////////////////////////
import type { IdType, UserClaim } from '@stonewall-defense/atlas-common';

// /////////////////////////////////////////////////////////////////////////////
// Local Imports
// /////////////////////////////////////////////////////////////////////////////
import { usePatchUser } from 'apiHooks/Users.Hook';
import type { IUser, IUserUpdate } from 'types/user';
import { AtlasModalHeader } from 'components/shared/AtlasModal/AtlasModal';
import { useRestrictAccess } from 'utils/Helpers';

// /////////////////////////////////////////////////////////////////////////////
// Styles
// /////////////////////////////////////////////////////////////////////////////
import styles from './AgGridUserAdminEditCellRenderer.module.css';

// /////////////////////////////////////////////////////////////////////////////
// Types
// /////////////////////////////////////////////////////////////////////////////
type UserFormData = {
  userId: IdType,
  givenName: string,
  familyName: string,
  active: boolean,
} & Record<UserClaim, boolean>;

// /////////////////////////////////////////////////////////////////////////////
// Config
// /////////////////////////////////////////////////////////////////////////////
const errorClass = `is-invalid ${styles['no-blur'] || ''}`;

// /////////////////////////////////////////////////////////////////////////////
// Helpers
// /////////////////////////////////////////////////////////////////////////////
function userToFormData({
  userId, givenName, familyName, active, claims,
}: IUser): UserFormData {
  return {
    userId,
    givenName,
    familyName,
    active,
    admin: claims.includes('admin'),
    data: claims.includes('data'),
    tech: claims.includes('tech'),
  };
}

function formDataToUser({
  userId, givenName, familyName, active, admin, data, tech,
}: UserFormData): IUserUpdate {
  return {
    userId,
    givenName,
    familyName,
    active,
    claims: [
      (admin ? 'admin' : ''),
      (data ? 'data' : ''),
      (tech ? 'tech' : ''),
    ].filter(Boolean) as UserClaim[],
  };
}

function EditUserModal({ user }: { user: IUser; }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const patchUser = usePatchUser();

  const toggle = () => setModalOpen(!modalOpen);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserFormData>({
    mode: 'onBlur',
    defaultValues: userToFormData(user),
  });

  const cancelUpdate = () => {
    toggle();
    reset(user);
  };

  const givenNameFormRegistration = register('givenName', { required: true });
  const familyNameFormRegistration = register('familyName', { required: true });
  const activeFormRegistration = register('active');
  const adminFormRegistration = register('admin');
  const dataFormRegistration = register('data');
  const techFormRegistration = register('tech');

  const onSubmit = (formData: UserFormData) => {
    patchUser(formDataToUser(formData))
      .then(toggle)
      .catch(() => {});
  };

  return (
    <>
      <Button color="link" onClick={toggle}><MdEdit /></Button>
      <Modal isOpen={modalOpen} toggle={cancelUpdate}>
        <Form
          className="bg-white container-lg g-0 rounded"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AtlasModalHeader toggle={toggle}>
            Modify
            { ' ' }
            {user.givenName}
            { ' ' }
            {user.familyName}
          </AtlasModalHeader>
          <ModalBody>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['givenName'] ? errorClass : '')}`}
                id="inputGivenNameInput"
                placeholder=" "
                autoComplete="off"
                onChange={givenNameFormRegistration.onChange}
                onBlur={givenNameFormRegistration.onBlur}
                name={givenNameFormRegistration.name}
                ref={givenNameFormRegistration.ref}
              />
              <Label htmlFor="inputGivenNameInput">First/Given Name</Label>
            </FormGroup>
            <FormGroup floating className="shadow-sm">
              <input
                type="text"
                className={`form-control ${(errors['familyName'] ? errorClass : '')}`}
                id="inputFamilyNameInput"
                autoComplete="off"
                placeholder=" "
                onChange={familyNameFormRegistration.onChange}
                onBlur={familyNameFormRegistration.onBlur}
                name={familyNameFormRegistration.name}
                ref={familyNameFormRegistration.ref}
              />
              <Label htmlFor="inputFamilyNameInput">Last/Family Name</Label>
            </FormGroup>
            <div className="d-flex justify-content-between mb-3">
              <FormGroup
                check
                inline
              >
                <Label check>
                  <input
                    type="checkbox"
                    onChange={activeFormRegistration.onChange}
                    onBlur={activeFormRegistration.onBlur}
                    name={activeFormRegistration.name}
                    ref={activeFormRegistration.ref}
                  />
                  Active
                </Label>
              </FormGroup>
              <FormGroup
                check
                row
              >
                <Label check>
                  <input
                    type="checkbox"
                    value="admin"
                    onChange={adminFormRegistration.onChange}
                    onBlur={adminFormRegistration.onBlur}
                    name={adminFormRegistration.name}
                    ref={adminFormRegistration.ref}
                  />
                  Admin
                </Label>
                <Label check>
                  <input
                    type="checkbox"
                    value="data"
                    onChange={dataFormRegistration.onChange}
                    onBlur={dataFormRegistration.onBlur}
                    name={dataFormRegistration.name}
                    ref={dataFormRegistration.ref}
                  />
                  Data Scientist
                </Label>
                <Label check>
                  <input
                    type="checkbox"
                    value="tech"
                    onChange={techFormRegistration.onChange}
                    onBlur={techFormRegistration.onBlur}
                    name={techFormRegistration.name}
                    ref={techFormRegistration.ref}
                  />
                  Technician
                </Label>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={cancelUpdate}>
              Cancel
            </Button>
            <input
              className="btn btn-primary text-dark"
              disabled={(!errors || (Object.keys(errors)).length > 0)}
              type="submit"
              value="Update"
            />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Exports
// /////////////////////////////////////////////////////////////////////////////
function AgGridUserAdminEditCellRenderer({ data } : ICellRendererParams) {
  const restrictUpdates = useRestrictAccess('admin');

  if (!restrictUpdates) return <EditUserModal user={data} />;
  return null;
}

export default AgGridUserAdminEditCellRenderer;
