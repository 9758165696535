import React from 'react';
import { FaUserCog } from 'react-icons/fa';

import type { IRoute } from 'types/route';
import UserAdmin from 'views/UserAdmin/UserAdmin';

const userAdminRoute:IRoute[] = [{
  component: <UserAdmin />,
  icon: <FaUserCog />,
  label: 'Admin',
  path: '/Admin',
  hideSidebarLink: true,
}];

export default userAdminRoute;
