import React, { useState } from 'react';
import { latLngBounds, type LatLngExpression } from 'leaflet';
import { useMap } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { MdFilterCenterFocus } from 'react-icons/md';
import { BiTargetLock } from 'react-icons/bi';

interface ICustomMapZoomControls {
  coordinates: LatLngExpression[],
  includeAutoZoom: boolean,
}

function CustomMapZoomControls({ includeAutoZoom, coordinates } : ICustomMapZoomControls) {
  const leafletMap = useMap();
  const [autoZoomEnabled, setAutoZoom] = useState(false);

  const centerAllMissions = () => {
    if (coordinates.length === 0) {
      leafletMap.setView([40.025, -74.59]);
      return;
    }

    leafletMap.fitBounds(latLngBounds(coordinates), { padding: [50, 50] });
  };

  const zoomIn = () => {
    leafletMap.zoomIn();
    if (includeAutoZoom) {
      setAutoZoom(false);
    }
  };

  const zoomOut = () => {
    leafletMap.zoomOut();
    if (includeAutoZoom) {
      setAutoZoom(false);
    }
  };

  const toggleAutoZoom = () => {
    setAutoZoom(!autoZoomEnabled);
  };

  return (
    <Control position="topleft">
      <div className="btn-group-vertical" role="group">
        <button
          type="button"
          onClick={zoomIn}
          className="btn btn-primary border border-primary p-2 m-0"
        >
          +
        </button>
        <button
          type="button"
          onClick={centerAllMissions}
          className="btn btn-primary border border-primary p-2 m-0"
        >
          <MdFilterCenterFocus />
        </button>
        {
          includeAutoZoom && (
            <button
              type="button"
              onClick={toggleAutoZoom}
              className={`btn btn-primary border p-2 m-0 ${autoZoomEnabled ? 'border-info text-info' : 'border-primary'}`}
            >
              <BiTargetLock />
            </button>
          )
        }
        <button
          type="button"
          onClick={zoomOut}
          className="btn btn-primary border border-primary p-2 m-0"
        >
          -
        </button>
      </div>
    </Control>
  );
}

export default CustomMapZoomControls;
