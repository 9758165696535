// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import type { SendResult } from '@rpldy/sender';
import { FILE_STATES, type SendOptions } from '@rpldy/shared';
import Uploady, { type BatchItem } from '@rpldy/uploady';
import { FormGroup, Input, Label } from 'reactstrap';
import Swal from 'sweetalert2';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { usePostNewTakParams } from 'apiHooks/Tak.Hook';
import UploadyDropZone from 'components/shared/Uploady/UploadyDropZone';
import UploadyUploadButton from 'components/shared/Uploady/UploadyUploadButton';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
export default function SetupTakIntegration() {
  const [ipAddress, setIpAddress] = React.useState<string>('');
  const [hasFile, setHasFile] = React.useState(false);
  const [showOnlyTrackedCargo, setShowOnlyTrackedCargo] = React.useState(true);

  const postNewTakParams = usePostNewTakParams();

  const setClientKey = (
    items: BatchItem[],
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const sendResponse = postNewTakParams(items, sendOptions);

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        if (data.state === FILE_STATES.ERROR) {
          Swal.fire({
            title: 'Upload Error',
            text: 'Failed to configure TAKServer connection',
            icon: 'error',
          }).catch(() => {});
        } else {
          window.location.reload();
        }

        return data;
      }).catch(() => {}),
    } as SendResult;
  };

  return (
    <div className="d-flex justify-content-center bg-gray gap-3">
      <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
        <Uploady
          autoUpload={false}
          accept=".p12,.pem"
          send={setClientKey}
        >

          <FormGroup floating className="mt-2">
            <input
              className="form-control"
              value={ipAddress}
              id="floatingIpAddressInput"
              onChange={(e) => setIpAddress(e.target.value)}
            />
            <Label for="floatingIpAddressInput">
              TAKServer IP Address
            </Label>
          </FormGroup>

          <FormGroup switch className="mt-2">
            <Input
              type="switch"
              role="switch"
              checked={showOnlyTrackedCargo}
              onClick={() => setShowOnlyTrackedCargo(!showOnlyTrackedCargo)}
            />
            <Label check>Show only tracked records</Label>
          </FormGroup>

          {
            hasFile
              ? <p>Key ready to upload</p>
              : (
                <UploadyDropZone
                  newFileCallback={() => setHasFile(true)}
                  dropZoneText="Drop key file or click here..."
                />
              )
          }

          <UploadyUploadButton
            disabled={!ipAddress}
            params={{ ipAddress, showOnlyTrackedCargo }}
          />
        </Uploady>

      </div>
    </div>
  );
}
