// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect } from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { Button, Input } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import type {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from '@ag-grid-community/core';
import { MdFilterAlt } from 'react-icons/md';
import isEqual from 'lodash-es/isEqual';
import { useAtom } from 'jotai';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { useGetUserProfiles } from 'apiHooks/Users.Hook';
import { FILTER_TYPES } from 'utils/Constants';
import { userOrganizationName } from 'utils/Helpers';
import type { AgGridUser } from 'types/user';

import type {
  IColumnFilter,
  IColumnFilterSet,
  IColumnFilterBoolean,
} from 'types/filter';
import Loading from 'components/shared/Loading/Loading';
import Filter, { isFilterApplied, passesTextFilter } from 'components/shared/Filter/Filter';
import AtlasUserCacheAtom from 'store/Atlas.store';
import UserAdminAgGridColDefsAtom from './UserAdminAgGridColDefs.store';
import { UserAdminFilterAtom, UserAdminInitialFilterAtom } from './UserAdminFilter.store';

import AddUserModal from './AddNewUser';
import { AG_GRID_USER_ADMIN_FIELDS } from './UserAdminTableColumns';

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
const passesBooleanFilter = (booleanFilter: IColumnFilterBoolean, colData: boolean) => (
  ((booleanFilter.options[0] === colData && booleanFilter.filterValues[0] === true)
  || (booleanFilter.options[1] === colData && booleanFilter.filterValues[1] === true)
  || (booleanFilter.filterValues[1] === booleanFilter.filterValues[0]))
);

const passesSetFilter = (setFilter: IColumnFilterSet, colData: string | number) => {
  const idx = setFilter.options.indexOf(colData);

  return idx === -1 || setFilter.filterValues[idx] === true;
};

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function UserAdmin() {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);
  const [rows, setRows] = React.useState<AgGridUser[]>([]);
  const [gridApi, setGridApi] = React.useState<GridApi | undefined>();
  const [columnApi, setColumnApi] = React.useState<ColumnApi>();
  const [atlasUserCache, setAtlasUserCache] = useAtom(AtlasUserCacheAtom);
  const [columnDefs] = useAtom(UserAdminAgGridColDefsAtom);
  const [appliedFilter, setAppliedFilter] = useAtom(UserAdminFilterAtom);
  const [userAdminInitialFilter, setUserAdminInitialFilter] = useAtom(UserAdminInitialFilterAtom);
  const {
    data: profilesData,
    isLoading,
    isError,
    error: profilesError,
  } = useGetUserProfiles();

  React.useEffect(() => {
    if (rows.length > 0) {
      setAppliedFilter(appliedFilter);
      setUserAdminInitialFilter(appliedFilter);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    rows,
    setAppliedFilter,
    setUserAdminInitialFilter,
  ]);

  React.useEffect(() => {
    const visibleCols = AG_GRID_USER_ADMIN_FIELDS.filter((key) => !appliedFilter[key]?.isHidden);
    const hiddenCols = AG_GRID_USER_ADMIN_FIELDS.filter((key) => appliedFilter[key]?.isHidden);
    columnApi?.setColumnsVisible(visibleCols, true);
    columnApi?.setColumnsVisible(hiddenCols, false);
    gridApi?.onFilterChanged();
  }, [appliedFilter, gridApi, columnApi]);

  React.useEffect(() => {
    if (!atlasUserCache.UserAdminAgGridColDefsAtom
      || !atlasUserCache.UserAdminFilterAtom
      || !atlasUserCache.UserAdminInitialFilterAtom) {
      setAtlasUserCache((prev) => ({
        ...prev,
        UserAdminAgGridColDefsAtom,
        UserAdminFilterAtom,
        UserAdminInitialFilterAtom,
      }));
    }
  }, [atlasUserCache]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!profilesData) return;

    setRows(profilesData);
    gridApi?.setRowData(profilesData);
  }, [gridApi, profilesData]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (<div>{profilesError as string}</div>);
  }

  if (!rows?.[0]) {
    return <div>No Data</div>;
  }

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const externalFilter = (node: IRowNode<AgGridUser>) => (
    AG_GRID_USER_ADMIN_FIELDS.reduce((isDisplayed, key) => {
      const agGridUserKey = key;
      const colFilter: IColumnFilter | undefined = appliedFilter[key];
      const nodeData = node.data;

      if (!isDisplayed || colFilter === undefined || colFilter.isHidden || nodeData === undefined) {
        return isDisplayed;
      }

      if (colFilter.filterType === FILTER_TYPES.TEXT) {
        return passesTextFilter(colFilter, nodeData[agGridUserKey] as string);
      }

      if (colFilter.filterType === FILTER_TYPES.BOOLEAN) {
        return passesBooleanFilter(colFilter, nodeData[agGridUserKey] as boolean);
      }

      if (colFilter.filterType === FILTER_TYPES.SET) {
        return passesSetFilter(colFilter, nodeData[agGridUserKey] as string | number);
      }

      return isDisplayed;
    }, true)
  );

  const onSearchQueryChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    gridApi?.setQuickFilter(e.target.value);
  };

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100">
      <h2 className="text-secondary px-3">
        {`${userOrganizationName()}: All Users`}
      </h2>
      <div className="d-flex justify-content-between bg-dark bg-gradient rounded-top p-3">
        <AddUserModal />

        <Input
          className="w-50"
          placeholder="Search..."
          onChange={onSearchQueryChange}
        />

        <div className="d-flex gap-3">
          <Button
            title="Filter Users"
            className="align-self-center border-0 bg-transparent"
            onClick={() => setShowFilterMenu(!showFilterMenu)}
          >
            <MdFilterAlt className={(isEqual(appliedFilter, userAdminInitialFilter) ? 'text-white' : 'text-primary')} />
          </Button>
        </div>
      </div>
      <div className="ag-theme-material w-100 h-100">

        <Filter filterName="Users" isOpened={showFilterMenu} />

        <AgGridReact
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={columnDefs}
          animateRows
          maintainColumnOrder
          isExternalFilterPresent={isFilterApplied}
          doesExternalFilterPass={externalFilter}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
        />
      </div>
    </div>
  );
}

export default UserAdmin;
