import { useAtom } from 'jotai';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import type { IColumnFilterDateTime } from 'types/filter';
import { DISABLED_TEXT_COLOR_CLASS, ENABLED_TEXT_COLOR_CLASS } from 'utils/Constants';
import type { FilterAtom } from './Filter';

interface IDateTimeFilterTypeComponent {
  field: string;
  dateTimeFilter: IColumnFilterDateTime,
  filterAtom: FilterAtom,
}

function DateTimeFilter({ dateTimeFilter, field, filterAtom }: IDateTimeFilterTypeComponent) {
  const [currentFilter, setCurrentFilter] = useAtom(filterAtom);
  const { startDateTime, endDateTime } = dateTimeFilter.filterValue;
  const startDateObj = startDateTime ? new Date(startDateTime) : null;
  const endDateObj = endDateTime ? new Date(endDateTime) : null;

  return (
    <div className="d-flex align-items-center">
      <span className={`px-1 text-nowrap user-select-none' ${dateTimeFilter.isHidden ? DISABLED_TEXT_COLOR_CLASS : ENABLED_TEXT_COLOR_CLASS}`}>
        between
      </span>
      <ReactDatePicker
        showIcon
        showTimeSelect
        selected={startDateObj}
        disabled={dateTimeFilter.isHidden}
        onChange={(date) => setCurrentFilter({
          ...currentFilter,
          [field]: {
            ...dateTimeFilter,
            filterValue: {
              ...dateTimeFilter.filterValue,
              startDateTime: date,
            },
          },
        })}
        timeFormat="HH:mm"
        dateFormat="MMMM d, yyyy HH:mm"
        isClearable
        timeClassName={() => 'text-body'}
      />
      <span className={`px-1 text-nowrap user-select-none' ${dateTimeFilter.isHidden ? DISABLED_TEXT_COLOR_CLASS : ENABLED_TEXT_COLOR_CLASS}`}>
        and
      </span>
      <ReactDatePicker
        showTimeSelect
        showIcon
        selected={endDateObj}
        disabled={dateTimeFilter.isHidden}
        onChange={(date) => setCurrentFilter({
          ...currentFilter,
          [field]: {
            ...dateTimeFilter,
            filterValue: {
              ...dateTimeFilter.filterValue,
              endDateTime: date,
            },
          },
        })}
        timeFormat="HH:mm"
        isClearable
        dateFormat="MMMM d, yyyy HH:mm"
        timeClassName={() => 'text-body'}
      />
    </div>
  );
}

export default DateTimeFilter;
