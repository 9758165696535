// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { type ICellRendererParams } from '@ag-grid-community/core';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { PositioningMethod } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { getTextColorTOLow } from 'utils/Helpers';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function AgGridPosMethodCellRenderer(
  { data }: ICellRendererParams<{ uncertainty: number, posMethod: PositioningMethod }>,
) {
  return data?.posMethod ? (
    <div>
      <span className={`${getTextColorTOLow(data.uncertainty || 0, 1500, 100)}`}>
        {data.uncertainty}
        {' '}
        m
      </span>
      {' ('}
      {data.posMethod}
      )
    </div>
  ) : null;
}

export default AgGridPosMethodCellRenderer;
