import React from 'react';
import { useUploady, useBatchAddListener, type Batch } from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';

interface IUploadyDropZone {
  newFileCallback: (batch: Batch) => void,
  dropZoneText: string,
}

function UploadyDropZone({ newFileCallback, dropZoneText }: IUploadyDropZone) {
  const uploady = useUploady();
  useBatchAddListener(newFileCallback);

  return (
    <UploadDropZone
      className="d-flex justify-content-center align-items-center drop-zone"
      onDragOverClassName="active"
      extraProps={{ onClick: () => uploady.showFileUpload() }}
    >
      <span>{ dropZoneText }</span>

    </UploadDropZone>
  );
}

export default UploadyDropZone;
