// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { Nullable } from 'types/common';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
export function getTime(dateStr: Nullable<string>, fallback = new Date()) {
  if (!dateStr) {
    return fallback;
  }

  const dt = new Date(dateStr);
  if (Number.isNaN(dt.getTime())) {
    return fallback;
  }

  return dt;
}

export function getNumber(arg: Nullable<string>, fallback = 0) {
  if (!arg) {
    return fallback;
  }

  const parsed = Number.parseInt(arg, 10);
  return Number.isNaN(parsed) ? fallback : parsed;
}
