// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { useAtom } from 'jotai';
import type { atomWithReset } from 'jotai/utils';
import { Collapse } from 'react-collapse';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { DeviceFilterAtom } from 'views/Dashboard/DeviceFilter.store';
import { AG_GRID_DEVICE_HEADER_MAP } from 'views/Dashboard/DeviceTableColumns';

import { LocationFilterAtom } from 'components/shared/LocationsTable/LocationFilter.store';
import { AG_GRID_LOCATION_HEADER_MAP } from 'components/shared/LocationsTable/LocationTableColumns';

import { TrackingFilterAtom } from 'views/Track/TrackingFilter.store';
import { AG_GRID_TRACKING_HEADER_MAP } from 'views/Track/TrackingTableColumns';

import { UserAdminFilterAtom } from 'views/UserAdmin/UserAdminFilter.store';
import { AG_GRID_USER_ADMIN_HEADER_MAP } from 'views/UserAdmin/UserAdminTableColumns';

import type { IColumnFilterText, ICustomFilter } from 'types/filter';
import FilterOption from './FilterOption';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interface
// ////////////////////////////////////////////////////////////////////////////
const FILTER_NAMES = [
  'Devices',
  'Locations',
  'Tracking',
  'Users',
] as const;

type FilterName = typeof FILTER_NAMES[number];

export type FilterAtom = ReturnType<typeof atomWithReset<ICustomFilter>>;

type FilterRecordEntry = {
  filterAtom: FilterAtom,
  headerMap: Record<string, string>;
};

const FILTERS: Record<FilterName, FilterRecordEntry> = {
  Devices: {
    filterAtom: DeviceFilterAtom,
    headerMap: AG_GRID_DEVICE_HEADER_MAP,
  },
  Locations: {
    filterAtom: LocationFilterAtom,
    headerMap: AG_GRID_LOCATION_HEADER_MAP,
  },
  Tracking: {
    filterAtom: TrackingFilterAtom,
    headerMap: AG_GRID_TRACKING_HEADER_MAP,
  },
  Users: {
    filterAtom: UserAdminFilterAtom,
    headerMap: AG_GRID_USER_ADMIN_HEADER_MAP,
  },
};

interface IFilterProps {
  filterName: FilterName;
  isOpened: boolean;
}

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
export const isFilterApplied = () => (true);

export const passesTextFilter = (textFilter: IColumnFilterText, colData: string) => (
  !textFilter.filterValue.trim() || (colData || '').toUpperCase().includes(textFilter.filterValue.toUpperCase())
);

// ////////////////////////////////////////////////////////////////////////////
// Components
// ////////////////////////////////////////////////////////////////////////////
function Filter({ filterName, isOpened }: IFilterProps) {
  const { filterAtom, headerMap } = FILTERS[filterName];

  const [initialFilter] = useAtom(filterAtom);

  return (
    <Collapse theme={{ collapse: 'react-collapse-collapse-menu' }} isOpened={isOpened}>
      <div className="bg-secondary text-white py-4 shadow">
        <h3 className="row px-4 border-bottom text-center text-white mb-1 user-select-none">
          {`${filterName} Filter`}
        </h3>
        <div className="container flex-column">
          <table>
            <tbody>
              {Object.keys(initialFilter).map((field) => (
                <FilterOption
                  key={field}
                  col={field}
                  headerMap={headerMap}
                  filterAtom={filterAtom}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Collapse>
  );
}

export default Filter;
