import { useAtom } from 'jotai';
import React from 'react';
import type { IColumnFilterNumberRange } from 'types/filter';
import { FILTER_NUMBER_RANGE_TYPES } from 'utils/Constants';
import type { FilterAtom } from './Filter';

interface INumberRangeFilterTypeComponent {
  field: string;
  numberRangeFilter: IColumnFilterNumberRange,
  filterAtom: FilterAtom;
}

function NumberRangeFilter({
  numberRangeFilter,
  field,
  filterAtom,
}: INumberRangeFilterTypeComponent) {
  const [currentFilter, setCurrentFilter] = useAtom(filterAtom);

  return (
    <>
      <select
        value={numberRangeFilter.rangeType || ''}
        onChange={(e) => setCurrentFilter({
          ...currentFilter,
          [field]: {
            ...numberRangeFilter,
            rangeType: e.target.value,
          },
        })}
        disabled={numberRangeFilter.isHidden}
      >
        <option value={FILTER_NUMBER_RANGE_TYPES.EQUAL_TO}>Equals</option>
        <option value={FILTER_NUMBER_RANGE_TYPES.GREATER_THAN}>Greater Than</option>
        <option value={FILTER_NUMBER_RANGE_TYPES.LESS_THAN}>Less Than</option>
      </select>
      <input
        type="number"
        disabled={numberRangeFilter.isHidden}
        value={numberRangeFilter.filterValue || ''}
        onChange={(e) => setCurrentFilter({
          ...currentFilter,
          [field]: {
            ...numberRangeFilter,
            filterValue: Number.parseInt(e.target.value, 10),
          },
        })}
      />
    </>
  );
}

export default NumberRangeFilter;
