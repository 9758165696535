import React from 'react';
import { MdOutlineSsidChart } from 'react-icons/md'; // cspell: disable-line

import type { IRoute } from 'types/route';
import Device from './Device';
import DeviceLogs from './DeviceLogs';

const trackingRoute:IRoute[] = [
  {
    component: <Device />,
    icon: <MdOutlineSsidChart />, // cspell: disable-line
    label: 'Device',
    path: '/Device/:serial',
    hideSidebarLink: true,
  },
  {
    component: <DeviceLogs />,
    icon: <MdOutlineSsidChart />, // cspell: disable-line
    label: 'Device',
    path: '/Device/:serial/logs',
    hideSidebarLink: true,
  },
];

export default trackingRoute;
