import React from 'react';
import {
  Route,
  Navigate,
} from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';

import Login from 'views/Login/Login';

import logistics from 'assets/img/logistics.png';
import styles from './LoginLayout.module.css';

function LoginLayout() {
  return (
    <div
      className={`vh-100 vw-100 ${styles['fullpage'] || ''}`}
      style={{ backgroundImage: `url(${logistics})` }}
    >
      <SlideRoutes>
        <Route
          path="/"
          key="Login"
          element={<Login />}
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </SlideRoutes>
    </div>
  );
}

export default LoginLayout;
