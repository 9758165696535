// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { useNavigate, useParams } from 'react-router-dom';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import DeviceMap from './DeviceMap';
import DeviceDetails from './DeviceDetails';

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function Device() {
  const { serial } = useParams();
  const navigate = useNavigate();

  if (!serial) {
    navigate('/');
    return <div />;
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100" style={{ overflowY: 'scroll' }}>
      <h2 className="text-secondary px-3">
        Device Details for
        {' '}
        {serial}
      </h2>
      <DeviceMap serial={serial} />
      <DeviceDetails serial={serial} />
    </div>
  );
}

export default Device;
