import { atomWithReset } from 'jotai/utils';

import type { ICustomFilter } from 'types/filter';
import {
  FILTER_TYPES,
} from 'utils/Constants';
import type { TrackingField } from './TrackingTableColumns';

const TRACKING_INITIAL_FILTERS: ICustomFilter<TrackingField> = {
  recordName: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  recordDescription: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  priority: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  serial: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  reportTime: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
  location: {
    filterValue: '',
    isHidden: false,
    filterType: FILTER_TYPES.TEXT,
  },
};

const TrackingFilterAtom = atomWithReset<ICustomFilter<TrackingField>>(
  TRACKING_INITIAL_FILTERS,
);

const TrackingInitialFilterAtom = atomWithReset<ICustomFilter<TrackingField>>(
  TRACKING_INITIAL_FILTERS,
);

export {
  TrackingFilterAtom,
  TrackingInitialFilterAtom,
};
