import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useUploady, UPLOADER_EVENTS } from '@rpldy/uploady';

interface IUploadButton {
  disabled: boolean,
  params?: Record<string, unknown>,
}

function UploadyUploadButton({ disabled, params }: IUploadButton) {
  const [submitted, setSubmitted] = React.useState(false);
  const { processPending, on } = useUploady();

  const upload = () => {
    setSubmitted(true);

    if (params) {
      processPending({ params });
    } else {
      processPending();
    }
  };

  on(UPLOADER_EVENTS.ITEM_FINALIZE, () => setSubmitted(false));

  return (
    <Button
      disabled={disabled || submitted}
      onClick={upload}
    >
      {submitted ? <Spinner size="sm" /> : 'Submit'}
    </Button>
  );
}

export default UploadyUploadButton;
