import { useAtom } from 'jotai';
import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import type { IColumnFilterBoolean, ICustomFilter } from 'types/filter';
import type { atomWithReset } from 'jotai/utils';
import FilterButton from './FilterButton';

interface IBooleanFilterTypeComponent {
  field: string,
  booleanFilter: IColumnFilterBoolean,
  filterAtom: ReturnType<typeof atomWithReset<ICustomFilter>>,
}

function BooleanFilter({ booleanFilter, field, filterAtom }: IBooleanFilterTypeComponent) {
  const [currentFilter, setCurrentFilter] = useAtom(filterAtom);

  const setFilterChange = (isFiltered: boolean, idx: number) => {
    const newFilter = [...booleanFilter.filterValues];
    newFilter.splice(idx, 1, isFiltered);
    setCurrentFilter({
      ...currentFilter,
      [field]: {
        ...booleanFilter,
        filterValues: newFilter,
      },
    });
  };

  return (
    <div className="d-flex gap-1 container py-1 border-bottom">
      <FilterButton
        active={!!booleanFilter.filterValues[0]}
        disabled={booleanFilter.isHidden}
        onClick={() => setFilterChange(!booleanFilter.filterValues[0], 0)}
      >
        <FaCheck className="text-success" />
      </FilterButton>
      <FilterButton
        active={!!booleanFilter.filterValues[1]}
        disabled={booleanFilter.isHidden}
        onClick={() => setFilterChange(!booleanFilter.filterValues[1], 1)}
      >
        <FaTimes className="text-danger" />
      </FilterButton>
    </div>
  );
}

export default BooleanFilter;
