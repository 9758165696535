const FILTER_TYPES = {
  TEXT: 'TEXT',
  SET: 'SET',
  NUMBER_RANGE: 'NUMBER_RANGE',
  DATE_TIME: 'DATE_TIME',
  TOGGLE: 'TOGGLE',
  BOOLEAN: 'BOOLEAN',
} as const;

const FILTER_NUMBER_RANGE_TYPES = {
  GREATER_THAN: '>',
  LESS_THAN: '<',
  EQUAL_TO: '=',
} as const;

export {
  FILTER_TYPES,
  FILTER_NUMBER_RANGE_TYPES,
};
