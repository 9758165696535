export function getTextColorTOHigh(val: number, threshold: number, objective: number) {
  if (val > objective) {
    return 'text-success';
  }

  if (val > threshold) {
    return 'text-warning';
  }

  return 'text-danger';
}

export function getTextColorTOLow(val: number, threshold: number, objective: number) {
  if (val < objective) {
    return 'text-success';
  }

  if (val < threshold) {
    return 'text-warning';
  }

  return 'text-danger';
}
