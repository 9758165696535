// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { CookiesProvider, Cookies } from 'react-cookie';
import { ProSidebarProvider } from 'react-pro-sidebar';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import COOKIE_NAME from 'utils/constants/CookieName';
import App from './App';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      const { status } = error as Response;
      if (status === 401 || status === 403) {
        const cookies = new Cookies();
        cookies.remove(COOKIE_NAME, { path: '/' });
        queryClient.clear();
        window.location.href = '/';
        // jotaiStore = createStore();
        queryClient.clear();
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const { status } = error as Response;
        return (status !== 401 && status !== 403);
      },
    },
  },
});
const rootElement = document.querySelector('#root');

if (rootElement) {
  createRoot(rootElement).render(
    <React.StrictMode>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ProSidebarProvider>
            <Router>
              <App />
            </Router>
          </ProSidebarProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </React.StrictMode>,
  );
}
