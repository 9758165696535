// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import { swdHttpGet, swdHttpPatch, swdHttpPost } from '@stonewall-defense/swd-ui-utils';
import type {
  CurrentTrackingRecord,
  NewTrackingRecord,
  TrackingPriority,
  TrackingRecordAssoc,
  TrackingRecordWithHistory,
} from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { authToken, httpPut } from 'utils/Helpers';
import type { Nullable } from 'types/common';
import { getCurrentLocationsQuery, getDeviceCollectionQuery } from './Collection.Hook';

// ////////////////////////////////////////////////////////////////////////////
// Collection
// ////////////////////////////////////////////////////////////////////////////
const getTrackingCollectionQuery = {
  queryKey: ['GetTrackingCollection'],
  queryFn: () => swdHttpGet('/gaia/track', authToken() || '') as Promise<CurrentTrackingRecord[]>,
};

const useTrackingCollection = () => useQuery(getTrackingCollectionQuery);

const useGetTrackingAndLocations = () => (
  useQueries({
    queries: [
      getCurrentLocationsQuery,
      getDeviceCollectionQuery,
      getTrackingCollectionQuery,
    ],
  })
);

const usePostTrackingRecord = () => {
  const queryClient = useQueryClient();

  return (params: NewTrackingRecord) => swdHttpPost('/gaia/track', authToken() || '', params)
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetTrackingCollection'] }));
};

// ////////////////////////////////////////////////////////////////////////////
// Instance
// ////////////////////////////////////////////////////////////////////////////
const useRecordInstance = (recordId: number) => useQuery({
  queryKey: ['GetRecordInstance', recordId],
  queryFn: () => swdHttpGet(`/gaia/track/${recordId}`, authToken() || '') as Promise<TrackingRecordWithHistory>,
});

const usePatchRecordName = (recordId: number) => {
  const queryClient = useQueryClient();

  return (recordName: string) => swdHttpPatch(`/gaia/track/${recordId}`, authToken() || '', { recordName })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetRecordInstance', recordId] }));
};

const usePatchRecordDescription = (recordId: number) => {
  const queryClient = useQueryClient();

  return (recordDescription: Nullable<string>) => swdHttpPatch(`/gaia/track/${recordId}`, authToken() || '', { recordDescription })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetRecordInstance', recordId] }));
};

const usePatchRecordPriority = (recordId: number) => {
  const queryClient = useQueryClient();

  return (priority: TrackingPriority) => swdHttpPatch(`/gaia/track/${recordId}`, authToken() || '', { priority })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetRecordInstance', recordId] }));
};

// ////////////////////////////////////////////////////////////////////////////
// Association
// ////////////////////////////////////////////////////////////////////////////
const useUpsertAssociation = () => {
  const queryClient = useQueryClient();

  return (params: TrackingRecordAssoc) => httpPut('/gaia/track/assoc', authToken() || '', params)
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetTrackingCollection'] }));
};

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
export {
  useTrackingCollection,
  useGetTrackingAndLocations,
  usePostTrackingRecord,

  useRecordInstance,
  usePatchRecordName,
  usePatchRecordDescription,
  usePatchRecordPriority,

  useUpsertAssociation,
};
