import React from 'react';
import {
  Button,
  ModalFooter,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';
import Uploady, { FILE_STATES, type BatchItem } from '@rpldy/uploady';
import type { SendOptions } from '@rpldy/shared';
import type { SendResult } from '@rpldy/sender';
import Swal from 'sweetalert2';

import { usePatchClientKey } from 'apiHooks/Tak.Hook';
import { AtlasModalBody, AtlasModalContainer, AtlasModalHeader } from 'components/shared/AtlasModal/AtlasModal';
import {
  UploadyDropZone,
  UploadyUploadButton,
} from 'components/shared/Uploady/uploadyComponents';

interface IEditClientKeyModal {
  hasKey: boolean,
  keyHasFailed: boolean,
}

function getTakTextColor(hasKey: boolean, keyHasFailed: boolean) {
  if (hasKey) {
    return keyHasFailed ? 'text-danger' : 'text-success';
  }

  return 'text-warning';
}

function EditClientKeyModal({ hasKey, keyHasFailed }: IEditClientKeyModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [hasFile, setHasFile] = React.useState(false);

  const patchClientKey = usePatchClientKey();

  const toggle = () => {
    if (modalOpen) setHasFile(false);
    setModalOpen(!modalOpen);
  };

  const setClientKey = (
    items: BatchItem[],
    url: string | undefined,
    sendOptions: SendOptions,
  ) => {
    const sendResponse = patchClientKey(items, sendOptions);

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        if (data.state === FILE_STATES.ERROR) {
          Swal.fire({
            title: 'Upload Error',
            text: 'Failed to upload key file',
            icon: 'error',
          }).catch(() => {});
        } else {
          toggle();
        }

        return data;
      }).catch(() => {}),
    } as SendResult;
  };

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className={`text-nowrap lh-1 fs-4 ${getTakTextColor(hasKey, keyHasFailed)}`}>{hasKey ? `Has ${keyHasFailed ? 'invalid' : 'valid'} key` : 'No key recorded'}</span>
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>

      <AtlasModalContainer
        scrollable
        className="image-lightbox-modal"
        isOpen={modalOpen}
        toggle={toggle}
      >
        <Uploady
          autoUpload={false}
          accept=".p12,.pem"
          send={setClientKey}
        >
          <AtlasModalHeader toggle={toggle}>
            Update Client Key
          </AtlasModalHeader>

          <AtlasModalBody className="d-flex flex-column text-secondary fw-bold">
            {
              hasFile
                ? <p>Ready to upload</p>
                : (
                  <UploadyDropZone
                    newFileCallback={() => setHasFile(true)}
                    dropZoneText="Drop key file or click here..."
                  />
                )
            }
          </AtlasModalBody>

          <ModalFooter className="d-flex justify-content-between">
            <Button onClick={toggle}>Cancel</Button>
            <UploadyUploadButton
              disabled={!hasFile}
            />
          </ModalFooter>
        </Uploady>
      </AtlasModalContainer>
    </>
  );
}

export default EditClientKeyModal;
