// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from 'react';

import { swdHttpGet } from '@stonewall-defense/swd-ui-utils';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import Loading from 'components/shared/Loading/Loading';
import { authToken } from 'utils/Helpers';
import SetupTakIntegration from './SetupTakIntegration';
import ShowTakIntegration from './ShowTakIntegration';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
function Tak() {
  const [isConfigured, setIsConfigured] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    swdHttpGet('/gaia/tak', authToken() || '')
      .then(() => setIsConfigured(true))
      .catch(() => setIsConfigured(false))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="d-flex flex-column bg-gray bg-gradient px-3 py-2 h-100">
      <h2 className="text-secondary px-3">TAK Integration</h2>
      { isConfigured ? <ShowTakIntegration /> : <SetupTakIntegration /> }
    </div>
  );
}

export default Tak;
