// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { usePatchIpAddress } from 'apiHooks/Tak.Hook';
import AtlasModal from 'components/shared/AtlasModal/AtlasModal';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface IEditIpAddressModal {
  ipAddress: string,
  ipHasFailed: boolean,
}

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
function EditIpAddressModal({ ipAddress, ipHasFailed }: IEditIpAddressModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpIpAddress, setTmpIpAddress] = React.useState(ipAddress);

  const patchIpAddress = usePatchIpAddress();

  const toggle = () => setModalOpen(!modalOpen);
  const resetState = () => setTmpIpAddress(ipAddress);

  const onSubmit = () => patchIpAddress(tmpIpAddress)
    .then(() => {
      toggle();
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className={`text-nowrap lh-1 fs-4 ${ipHasFailed ? 'text-danger' : 'text-success'}`}>{ipAddress}</span>
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <AtlasModal
        title="Update TAKServer IP address"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={ipAddress !== tmpIpAddress}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="inputIpAddress"
            autoComplete="off"
            defaultValue={tmpIpAddress}
            onChange={(event) => setTmpIpAddress(event.target.value.trim())}
            placeholder=" "
          />
          <label htmlFor="inputIpAddress">
            TAKServer IP Address
          </label>
        </div>
      </AtlasModal>
    </>
  );
}

export default EditIpAddressModal;
