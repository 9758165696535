import React from 'react';
import {
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { usePatchSleepMinutes } from 'apiHooks/Details.Hook';

interface IEditSleepMinutesModal {
  serial: string,
  minSleepMinutes: number,
}

function EditSleepMinutesModal({ serial, minSleepMinutes }: IEditSleepMinutesModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpVal, setTmpVal] = React.useState(minSleepMinutes);

  const patch = usePatchSleepMinutes();

  const toggle = () => setModalOpen(!modalOpen);
  const resetState = () => setTmpVal(minSleepMinutes);

  const onSubmit = () => patch(serial, tmpVal)
    .then(() => {
      toggle();
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <span className="text-nowrap lh-1 fs-4">{minSleepMinutes}</span>
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <AtlasModal
        title="Update Minimum Sleep Minutes"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={minSleepMinutes !== tmpVal}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <input
            className="form-control mb-3"
            id="inputMinSleepMinutes"
            autoComplete="off"
            defaultValue={tmpVal}
            onChange={(event) => setTmpVal(Number.parseInt(event.target.value, 10))}
            placeholder=" "
          />
          <label htmlFor="inputMinSleepMinutes">
            Minimum Sleep Minutes
          </label>
        </div>
      </AtlasModal>
    </>
  );
}

export default EditSleepMinutesModal;
