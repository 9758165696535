// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  Button,
} from 'reactstrap';
import { MdEdit } from 'react-icons/md';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type { TrackingPriority } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import AtlasModal from 'components/shared/AtlasModal/AtlasModal';
import { usePatchRecordPriority } from 'apiHooks/Track.Hook';
import Priority from 'components/shared/Priority';

// ////////////////////////////////////////////////////////////////////////////
// Types and Interfaces
// ////////////////////////////////////////////////////////////////////////////
interface IEditPriorityModal {
  recordId: number,
  priority: TrackingPriority,
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function EditPriorityModal({ recordId, priority }: IEditPriorityModal) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [tmpVal, setTmpVal] = React.useState(priority);

  const patch = usePatchRecordPriority(recordId);

  const toggle = () => setModalOpen(!modalOpen);
  const resetState = () => setTmpVal(priority);

  const onSubmit = () => patch(tmpVal)
    .then(() => {
      toggle();
    })
    .catch(() => {});

  return (
    <>
      <div className="col d-flex align-items-end">
        <Priority priority={priority} />
        <Button color="link" onClick={toggle} className="py-0">
          <MdEdit />
        </Button>
      </div>
      <AtlasModal
        title="Update Record Name"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={priority !== tmpVal}
        onSubmit={onSubmit}
        onClosed={resetState}
      >
        <div className="form-floating">
          <span className="px-2">Priority:</span>
          <select
            className="form-select"
            value={priority}
            onChange={(e) => setTmpVal(e.target.value as TrackingPriority)}
          >
            <option value="low">Low</option>
            <option value="normal" selected>Normal</option>
            <option value="high">High</option>
            <option value="critical">Critical</option>
          </select>
        </div>
      </AtlasModal>
    </>
  );
}

export default EditPriorityModal;
