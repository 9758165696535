// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import type { BatchItem, SendOptions } from '@rpldy/shared';
import { send, type SendResult } from '@rpldy/sender';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  swdHttpGet, swdHttpPatch,
} from '@stonewall-defense/swd-ui-utils';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { authToken } from 'utils/Helpers';
import type { ITakParams } from 'types/takParams';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
const useGetTakParams = () => (
  useQuery({
    queryKey: ['GetTak'],
    queryFn: () => (
      swdHttpGet('/gaia/tak', authToken() || '') as Promise<ITakParams>
    ),
  })
);

const usePatchIpAddress = () => {
  const queryClient = useQueryClient();

  return (ipAddress: string) => swdHttpPatch('/gaia/tak', authToken() || '', { ipAddress })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetTak'] }));
};

const usePatchShowTrackedRecords = () => {
  const queryClient = useQueryClient();

  return (showOnlyTrackedCargo: boolean) => swdHttpPatch('/gaia/tak', authToken() || '', { showOnlyTrackedCargo })
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetTak'] }));
};

const usePatchClientKey = () => {
  const queryClient = useQueryClient();

  return (items: BatchItem[], sendOptions: SendOptions) => {
    const url = '/gaia/tak';

    const sendResponse = send(items, url, {
      ...sendOptions,
      method: 'PATCH',
      paramName: 'key',
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
    });

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetTak'] }).catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

const usePostNewTakParams = () => {
  const queryClient = useQueryClient();

  return (items: BatchItem[], sendOptions: SendOptions) => {
    const url = '/gaia/tak';

    const sendResponse = send(items, url, {
      ...sendOptions,
      method: 'POST',
      paramName: 'key',
      headers: {
        ...sendOptions.headers,
        Authorization: authToken() || '',
      },
    });

    return {
      ...sendResponse,
      request: sendResponse.request.then((data) => {
        queryClient.invalidateQueries({ queryKey: ['GetTak'] }).catch(() => {});

        return data;
      }),
    } as SendResult;
  };
};

export {
  useGetTakParams,
  usePatchIpAddress,
  usePatchShowTrackedRecords,
  usePatchClientKey,
  usePostNewTakParams,
};
