// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import {
  useQuery,
  useQueries,
} from '@tanstack/react-query';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import { swdHttpGet } from '@stonewall-defense/swd-ui-utils';
import type { FormattedLocation, DeviceCollectionEntry } from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { authToken } from 'utils/Helpers';

// ////////////////////////////////////////////////////////////////////////////
// Implementation
// ////////////////////////////////////////////////////////////////////////////
const getCurrentLocationsQuery = {
  queryKey: ['GetCurrentLocations'],
  queryFn: () => swdHttpGet('/gaia/locations/current', authToken() || '') as Promise<FormattedLocation[]>,
};

const useCurrentLocations = () => useQuery(getCurrentLocationsQuery);

const getDeviceCollectionQuery = {
  queryKey: ['GetDeviceCollection'],
  queryFn: () => swdHttpGet('/gaia/devices', authToken() || '') as Promise<DeviceCollectionEntry[]>,
};

const useDeviceCollection = () => useQuery(getDeviceCollectionQuery);

const useGetDevicesAndLocations = () => (
  useQueries({
    queries: [
      getCurrentLocationsQuery,
      getDeviceCollectionQuery,
    ],
  })
);

export {
  getCurrentLocationsQuery,
  getDeviceCollectionQuery,

  useCurrentLocations,
  useDeviceCollection,
  useGetDevicesAndLocations,
};
