// ////////////////////////////////////////////////////////////////////////////
// React Imports
// ////////////////////////////////////////////////////////////////////////////
import React from 'react';

// ////////////////////////////////////////////////////////////////////////////
// 3PP Imports
// ////////////////////////////////////////////////////////////////////////////
import { Button } from 'reactstrap';
import { MdListAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

// ////////////////////////////////////////////////////////////////////////////
// SWD Imports
// ////////////////////////////////////////////////////////////////////////////
import type {
  DeviceConfig,
  DeviceCore,
  DeviceFirmware,
  Versioned,
} from '@stonewall-defense/atlas-common';

// ////////////////////////////////////////////////////////////////////////////
// Local Imports
// ////////////////////////////////////////////////////////////////////////////
import { useDeviceInstance } from 'apiHooks/Details.Hook';
import Loading from 'components/shared/Loading/Loading';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { formatDate, useRestrictAccess } from 'utils/Helpers';
import formatFirmware from 'utils/helpers/formatFirmware';
import IccidModal from './IccidModal';
import ImeiModal from './ImeiModal';
import EditTimeoutModal from './EditTimeoutModal';
import EditSleepMinutesModal from './EditSleepMinutes';
import EditSleepSecondsModal from './EditSleepSeconds';

// ////////////////////////////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////////////////////////////
function Core({ device, isTech }: { device: DeviceCore, isTech: boolean }) {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h3 className="text-secondary px-3">Device Details</h3>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          ICCID
        </small>
        { device.iccid
          ? <IccidModal iccid={device.iccid} isTech={isTech} />
          : <p>None recorded</p> }
      </div>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          IMEI
        </small>
        { device.imei
          ? <ImeiModal imei={device.imei} isTech={isTech} />
          : <p>None recorded</p> }
      </div>
      { isTech && (
        <Button
          className="p-2 px-3 m-2"
          title="Show/Hide Details"
          onClick={() => navigate(`/Device/${device.serial}/logs`)}
        >
          Connection Logs
          {' '}
          <MdListAlt />
        </Button>
      )}
    </div>
  );
}

function Config({ serial, config }: { serial: string, config: DeviceConfig }) {
  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h3 className="text-secondary px-3">Device Config</h3>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Timeout Intervals
        </small>
        <EditTimeoutModal serial={serial} timeoutIntervals={config.timeoutIntervals as number} />
      </div>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Sleep Minutes
        </small>
        <EditSleepMinutesModal serial={serial} minSleepMinutes={config.minSleepMinutes as number} />
      </div>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Sleep Seconds
        </small>
        <EditSleepSecondsModal serial={serial} minSleepSeconds={config.minSleepSeconds as number} />
      </div>
      <div className="d-flex flex-column">
        <small className="text-nowrap align-items-end text-gray-dark">
          Synced to Parameters:
        </small>
        { config.specsUpdated ? <FaTimes className="text-danger" /> : <FaCheck className="text-success" /> }
      </div>
    </div>
  );
}

function Firmware({ firmware }: { firmware: Versioned<DeviceFirmware>[] }) {
  return (
    <div className="d-flex flex-column gap-2 p-3 border bg-light rounded-4">
      <h3 className="text-secondary px-3">Device Firware</h3>
      <div className="text-center overflow-auto" style={{ maxHeight: '300px' }}>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Bootloader</th>
              <th scope="col">Application</th>
              <th scope="col">First Reported</th>
            </tr>
          </thead>
          <tbody>
            {firmware.map((fw) => (
              <tr key={fw.validUntil}>
                <td>{fw.bootloaderVersion ? `v${fw.bootloaderVersion}` : 'N/A'}</td>
                <td>{formatFirmware(fw)}</td>
                <td>{formatDate(fw.validSince)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// ////////////////////////////////////////////////////////////////////////////
// Exports
// ////////////////////////////////////////////////////////////////////////////
function DeviceDetails({ serial }: { serial: string }) {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useDeviceInstance(serial);
  const isTech = !useRestrictAccess('tech');

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>{error as string}</div>;
  }

  return (
    <div className="d-flex justify-content-center bg-gray gap-3 pt-2">
      <Core device={data} isTech={isTech} />
      <Config serial={serial} config={data.config} />
      { isTech && <Firmware firmware={data.firmware} /> }
    </div>
  );
}

export default DeviceDetails;
