/**
 * Stonewall Defense API HTTP PUT
 *
 * @param urlPath URL Path of API call
 * @param auth authorization token
 * @param data data
 *
 * @returns Promise
 */
const httpPut = (
  urlPath: string,
  auth: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => new Promise<any>((resolve, reject) => {
  try {
    fetch(urlPath, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        resolve(response.status === 204 ? null : response.json());
      }
      reject(response);
    }).catch((error) => reject(error));
  } catch (error) {
    reject(error);
  }
});

export default httpPut;
